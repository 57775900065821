import React, { useState, useEffect } from "react";
import "./navbar.scss";
import Logo from "../../images/starpokes-logo.png";
import { Link } from "react-router-dom";
import axios from "../../axios";
import SignUp from "../SignUp/SignUp";
import ProfileNav from "../ProfileNav/ProfileNav";
import Searchbox from "./Searchbox/Searchbox";
import Locations from "./Locations/Locations"
import Languages from "./Language/Language"
import MobileNav from "./Mobile/MobileNav"
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, location, language, translations } from "../../store/actions";
import Signin from "../PopUps/SignIn/Signin";
import Categories from "../Categories/Categories"




import { Select } from "antd";

const Navbar = (props) => {
  const getToken = window.localStorage.getItem("user", JSON.stringify());

  //Sticky

  const getLocalUsers = JSON.parse(getToken);
  const [profileNav, setIsLoggedIn] = useState(getLocalUsers?.login);
  const locationi = useLocation();
  const login = useSelector((state) => state.login);

  const language = useSelector((state) => state.language);
  const location = useSelector((state) => state.location);

  const perkthimet = useSelector((state) => state.translations)


  const {lang} = perkthimet || {};

  const [openNav, setOpenNav] = useState("false")

  function triggerMobNav() {
      if(openNav === 'false'){

          setOpenNav('true')
      }else{
          setOpenNav('false');
      }
  }
  const closeModalFromChild = () => {
    setOpenNav('false');
  };

;


  const [isSticky, setSticky] = useState(false)


  

  useEffect(() => {

  
    setIsLoggedIn(getLocalUsers?.login);
    // window.addEventListener('scroll', sticky);  
  }, [])
  return (
    <div className={`${isSticky ? 'active' : 'nav'}`}>
      <div className="custom__container">
     
        <div className={`navbari`}>
            <div className='row main--navi'>
              <div className='col-lg-8 col-9'>
                <Link to='/'>
                  <img src={Logo} alt='' />
                </Link>
              </div>
              <div className='col-md-4 col-6 profilii d-none d-lg-block'>
                <div className='row no-gutters'>
                  <div className='col-md-4'>
                    <Searchbox item={props} />
                  </div>
                  <div className='col-md-4'>
                    {login?.login === true ? (
                      <ProfileNav></ProfileNav>
                    ) : (
                      <SignUp lang={lang}></SignUp>
                    )}
                  </div>
                   
                  <div className='col-2 select text-center'>
                    <Locations />
                  </div>
                  <div className="col-2">
                    <Languages />
                  </div>
            
                </div>
              </div>
              {/* { mobile nav} */}
              <div className="col-3 d-block d-lg-none ">
                <div className="mobile--nav" onClick={triggerMobNav}>
                    <div className="mob--burger" style={{textAlign:"right"}}>

                          {
                          openNav === 'false' ?<svg viewBox="0 0 100 80" width="40" height="40">
                          <rect width="100" fill="white" height="10" rx="8"></rect>
                          <rect y="30" fill="white" width="100" height="10" rx="8"></rect>
                          <rect y="60" fill="white" width="100" height="10" rx="8"></rect>
                        </svg> :  <svg viewBox="0 0 100 110" width="40" height="35">
                                  <line x1="10" y1="10" x2="100" y2="100" stroke="white" strokeWidth="15" />
                                  <line x1="100" y1="10" x2="10" y2="100" stroke="white" strokeWidth="15" />
                              </svg>
                          }
                      </div>

                    {/* // */}
                </div>
              </div>
              <div className="menu---container">
                      <div className="mobile--show" style={openNav === 'true' ? {display:"block"} : {display:"none"}}>
                        <div className="row">
                          <div className="col-6">
                          <Locations />
                          </div>
                          <div className="col-6">
                          <Languages />
                          </div>
                          <div className="col-12 mobile--buttons">
                          {login?.login === true ? (
                      <ProfileNav   closeModalFromChild={() =>
                        closeModalFromChild(setOpenNav('false'))
                      }></ProfileNav>
                    ) : (
                      <SignUp lang={lang}></SignUp>
                    )}
                              
                          </div>
                        </div>
                      </div>
              </div>

              {/* {end of mobile nav} */}
              <div className="col-12 d-block d-lg-none">
                        <Searchbox item={props} />
                    </div>
            </div>
          </div>
          <Categories />
      
      </div>
    </div>
  );
};

export default Navbar;
