export const increment = () =>{
    return{
        type: 'INCREMENT'
    }
}

export const decrement = () =>{
    return{
        type: 'DECREMENT'
    }
}

export const special = (number) =>{
    return{
        type: 'SPECIAL',
        payload: number
    }
}

export const login = (data) =>{
    return{
        type: 'LOGIN',
        payload: data
    }
}

export const artists = (data) => {
    return{
        type:'GETARTISTS',
        payload: data
    }
}

export const newcommers = (data) => {
    return{
        type:'NEWCOMMERS',
        payload: data
    }
}

export const trending = (data) => {
    return{
        type:'TRENDING',
        payload: data
    }
}

export const user = (data) => {
    return{
        type:'GETUSER',
        payload: data
    }
}
export const picture = (data) => {
    return{
        type:'PICTURE',
        payload: data
    }
}

export const categories = (data) => {
    return{
        type:'CATEGORIES',
        payload: data
    }
}

export const currency = (data) => {
    return{
        type:'CURRENCY',
        payload: data
    }
}

export const language = (data) => {
    return{
        type:'LANGUAGE',
        payload: data
    }
}

export const location = (data) => {
    return{
        type:'LOCATION',
        payload: data
    }
}
export const currentlocation = (data) => {
    return{
        type:'CURRENTLOCATION',
        payload: data
    }
}

export const translations = (data) => {
    return{
        type:'TRANSLATIONS',
        payload: data
    }
}

export const currentlanguage = (data) => {
    return{
        type:'CURRENTLANGUAGE',
        payload: data
    }
}


export const loginmodal = (data) => {
    return{
        type:'LOGINOPEN',
        payload: data
    }
}
export const signinmodal = (data) => {
    return{
        type:'SIGNINOPEN',
        payload: data
    }
}
export const forgotmodal = (data) => {
    return{
        type:'FORGOTOPEN',
        payload: data
    }
}

export const artistvideos = (data) => {
    return{
        type:'ARTISTVIDEOS',
        payload: data
    }
}

export const sidebarads = (data) => {
    return{
        type:'SIDEBAR',
        payload: data
    }
}

export const bottomads = (data) => {
    return{
        type:'BOTTOMADS',
        payload: data
    }
}
export const middleads = (data) => {
    return{
        type:'MIDDLEADS',
        payload: data
    }
}
export const belowads = (data) => {
    return{
        type:'BELOWADS',
        payload: data
    }
}