import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback,useRef } from 'react'
import {useDispatch, useSelector} from "react-redux"
// import {login} from "../../old-store/features/userSlice"
import "./style.scss";
import axios from "../../../axios";
import { ToastContainer,  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {user, picture} from "../../store/actions"

import ModalLogo from "../../../images/starpokes.svg";

import {createPortal} from 'react-dom'

import Singin from "../SignIn/Signin"
import Forgot from "../ForgotPassword/forgotpassword"
import List from "../Languages/list"

import {
    Route,
    BrowserRouter as Router,
    Switch,
    Link,
    useHistory,
  } from "react-router-dom";

import {login, user, picture, categories, currency, artists, translations,currentlanguage,currentlocation} from "../../../store/actions/index"


const modalElement = document.getElementById('modal-root')

export function Login({children, fade = false, props, defaultOpened = false}, ref) {


    let history = useHistory();

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(defaultOpened)

    const close = useCallback(() => setIsOpen(false), [])

    const perkthimet = useSelector((state) => state.translations);
    const currentLang = useSelector((state) => state.currentlanguage)


    const {lang} = perkthimet || {};

    
    const [errorMsg, setErrorMsg] = useState('');
    const [passError, setPassError] = useState("");
    const [shadow, setBoxShadow] = useState("");
    const [eye, setEye] = useState("");
    const [messageError, setMessageError] = useState("");
    const [pass, setMsg] = useState("");
    const [formControl, setFormControl] = useState();
    const [userAuth, setAuth] = useState({
      email: "",
      password: "",
    });
  

    if (typeof window !== 'undefined') {
      var path = history.protocol + '//' + history.host + '/someting'; // (or whatever)
    } else {
      // work out what you want to do server-side...
    }
    //lang states s
 
    // const [selectedLang, setSelectedLang] = useState(currentLang)
    // const [langFullName, setFullLangName] = useState('English(US)')
  
  
    const handleChange = (event) => {
      setAuth({ ...userAuth, [event.target.name]: event.target.value });
    };
  
    useEffect(() => {
  
    }, []);
  
    
    const handleLogin = async (event) => {
      event.preventDefault();
      
      let data = {
        email: event.target.email.value,
        password: event.target.password.value,
      };
      let logindata;
  
      try {
        logindata = await axios.post("api/artist/login", data);
     

          console.log(logindata.data?.user?.language)
      

        dispatch(user(logindata?.data?.user))
        dispatch(login({
          login: true,
        }))
        dispatch(currentlanguage(logindata.data?.user?.language))
        // dispatch(currentlocation(logindata?.data?.user?.homeCountryCode))

        dispatch(picture({
          image:{
            real:`https://api.starpokes.com/${logindata?.data?.user?.picturelocation}`,
            preview:null
          }
          
        }))
        dispatch(currency({
          currency:logindata?.data?.user?.currency
         }))
        //  axios.get(`api/categories/artistsbyparent/${logindata?.data?.user?.homeCountryCode}`).then(res => dispatch(artists(res.data)))

         setIsOpen(false)
      } catch (err) {
    
        console.log(err);
      }
      try {
        window.localStorage.setItem(
          "user",
          JSON.stringify({
            login: true,
            token: logindata.data.token,
            useri: logindata.data,
          })
        );
        window.localStorage.setItem(
          "profilepicture",
          JSON.stringify({
            image: logindata?.data?.user?.picturelocation,
          })
        );

        try { 
          // props.history.replace(`/`);
          // window.location.href = "/profile"
        
          if(logindata?.data.successPassword === 'temp'){
            history.replace(`/settings/password?reset=true`);
          }else{
            history.replace(history.location.pathname)
            // history.replace(`/profile/${logindata?.data?.username}`);
            login.close();
          }

          
          
   


      } catch (err) {
        console.log(err);
      }

      } catch (err) {
       
        setErrorMsg("Please fill in correct username and password fields");
      }
   
      // axios
      // .get(`api/categories/filteredcat/${logindata?.data?.user?.homeCountryCode}`)
      // .then((res) => {
      //   // getCategoriesall(res.data);
      //   dispatch(categories({
      //     categories: res.data,
      //   }))
      // })
  
     
    };




    const switchView = () => {
      // setIsOpen(false);
      // login.current.closeLogin()
      signin.current.open();
    };

    const switchToForgot = () => {
      // setIsOpen(false);
      // login.current.closeLogin()
      forgot.current.open()
    }

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      closeLogin: () => setIsOpen(false),
      close
    }), [close])
  
    const handleEscape = useCallback(event => {
      if (event.keyCode === 27) close()
    }, [close])

    useEffect(() => {
        if (isOpen) document.addEventListener('keydown', handleEscape, false)
        return () => {
          document.removeEventListener('keydown', handleEscape, false)
        }
      }, [handleEscape, isOpen])


      const signin = useRef(null)
      const forgot = useRef(null)


    return(
       createPortal(
         isOpen ?
         
         <div className={`modalis ${fade ? 'modalis-fade' : ''}`}>
         <div className="modalis-overlay" onClick={close} />
         <span role="button" className="modalis-close" aria-label="close" onClick={close}>
              X
         </span>
         <div className="modalis-body">
             <div className="container">
                 <div className="row">
                     <div className="col-md-12 modal-intro" style={{marginTop:"100px"}}>
                         <img src={ModalLogo} alt=""/>

                         <p>{lang?.pleaseSignInToContinue}!</p>
                         <span>{errorMsg}</span>
                     </div>
                 </div>
                 <div className="row">
                     <div className="col-md-12 modal-forma" style={{marginTop:"30px"}}>
                     <form onSubmit={handleLogin}>
                         <div className="input--modal--box">

                        <input
                                type='text'
                                name='email'
                                style={{ border: formControl, boxShadow: shadow }}
                                // className="form-control "
                                id='exampleInputEmail1'
                                onChange={handleChange}
                                aria-describedby='emailHelp'
                                placeholder={lang?.enterUsername}
                                required
                            />
                         </div>
                            <div className="input--modal--box">
                            <input
                                type={eye ? "text" : "password"}
                                name='password'
                                className='input__pass'
                                onChange={handleChange}
                                id='exampleInputPassword1'
                                placeholder={lang?.enterPassword}
                                maxLength='65'
                                required
                            />
                            </div>
                            <div className='forget__pass'>
                            {/* <NavLink
                                className="forgot__password"
                                to="forgot-password-email"
                            >
                                Forgot Password
                            </NavLink> */}
                            </div>
                            <div className="row">
                              <div className="col-md-6 check-boxat">
                              <input className='' type='radio' id="m" name='gender' value='M' />
                                  <label htmlFor="m">Remember Me</label>
                              </div>
                              <div className="col-md-6 text-center">
                                <a href="#" onClick={switchToForgot} style={{
                                  display:"block",
                                  marginTop:"17px"
                                }}>Forgot Password?</a>
                              </div>
                            </div>
                            <span className='error__msg'>
                            {pass} {messageError}
                            </span>
                            <span className='error__msg'>{passError}</span>
                           
                            <div className='login__button' style={{marginTop:"30px"}}>
                                <input type='submit' value='Login'/>

                                 <p>{lang?.dontHaveAnAccount} <a onClick={switchView} href="#">Sign UP</a></p>
                            </div>
                        </form>


                        <List />
                     </div>
                 </div>
             </div>
 
         </div>
         <Singin ref={signin}></Singin>
         <Forgot ref={forgot}></Forgot>
       </div>
       
 : null ,modalElement
       )
    )
}

export default forwardRef(Login)