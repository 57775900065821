// const data = []

const belowReducer = (state = null, action) => {
    switch(action.type){
        case 'BELOWADS':
            return state = action.payload;
        default: 
            return state;
    }
}

export default belowReducer;