import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {artists, newcommers, trending, user, picture, login, categories,currency, language, location, currentlocation, translations, currentlanguage} from '../../../store/actions/index';
import Banner from '../../Banner/Banner';

const MobileSubcategories = (props) => {
    let kategoria = props?.location.state.cat?.category_name

    

    let subkategorite = props?.location.state.cat?.subcats
    const perdoruesi = useSelector((state) => state.user)

    return(
        <>
           <Banner/>
           <div className="custom--container mobilecat--container">
               <div className="row">
                   <div className="col-md-12 text-center">
                       <h5 style={{fontSize:"21px",color:"#CB2A6F",margin:"20px 0"}}>{kategoria}</h5>
                   </div>
               </div>
                <div className="row">
                {
                 subkategorite?.map((item,index) => {
                  return(
                <div className="col-6">
                    <Link to={{pathname:`/singlesubcategory/${item.description}`,state:{cat: item, country:perdoruesi?.homeCountryCode}}}>
                    <div className="cat--box">
                        {/* <img src={`${path}/static/media/${item.description}.jpg`} className="img-fluid" alt=""/> */}
                        
                        <img src={`https://api.starpokes.com/${item.picturelocation}`} className="img-fluid" alt=""/>
                        <h5>{item.description}</h5>
                        {/* {catImage} */}
                    </div>
                    </Link>

                </div>
                  )
                })
          }
                </div>
            </div>
            </>
    )
}


export default MobileSubcategories