// const data = []

const translationsReducer = (state = null, action) => {
    switch(action.type){
        case 'TRANSLATIONS':
            return state = action.payload;
        default: 
            return state;
    }
}

export default translationsReducer;