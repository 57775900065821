import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";

const queryString = require('query-string');


const PaymentStatus = (props) => {


    const { paymentStatus } = useParams();
    const status = queryString.parse(props.location.search).status;


  

    setTimeout( function(){ 
        window.close();
    }, 0.001);
return(
    <div className="custom__container">
        <div className="row">
            <div className="col-md-12 text-center">
                {
                    status === 'success' ? <p>Thank you for your order!</p> : <p>Something went wrong!</p>
                }
                
              <h1 style={{textAlign:"center"}}>{status?.toUpperCase()}</h1>
            </div>
        </div>
    </div>
)

}



export default PaymentStatus