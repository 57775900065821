import React, { useState, useEffect} from "react";
import {
  NavLink,
  Link
} from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Vip from "./Vip/Vip"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";



import axios from "../../axios";
import "./vipcard.scss";
import {useDispatch, useSelector, connect} from 'react-redux'

import { LazyLoadImage,LazyLoadComponent  } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
//adds
import sllogan from "../../images/sllogan.png" 
import frutomania from "../../images/Add fruto.png"
import humme from "../../images/Add humme.png"

import HomeSingleAdd from "../Adds/HomeSingleAdd"
import TwoAdds from "../Adds/TwoAdds"

import {artists,belowads, middleads, bottomads, mapStateToProps} from "../../store"


const tokeni = window.localStorage.getItem("user", JSON.stringify());
const user = JSON.parse(tokeni);



const Vipcard = (props) => {
  // const dispatch = useDispatch();

  const newcomers = useSelector(state => state.newcomers )
  const trending = useSelector(state => state.trending )
  const currentlocation = useSelector(state => state.currentlocation)

  const artistvideos = useSelector(state => state.artistvideos)

  const belowads = useSelector(state => state.belowads)
  const middleads = useSelector(state => state.middleads)
  const bottomads = useSelector(state => state.bottomads)


  let videosFormatted = artistvideos?.slice(0,4)


  //get each category returned by country 


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 55
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 30
    }
  };



  // dispatch(getPosts())

      useEffect(() => {
    
      
      }, [])

 
 
  return (

<div className="home--vips">

<div className=" newcomers videos--container  vip--container">
   <div className="carousel--container d-block d-md-none videos">
     <div className="heading--text">Videos</div>
     <div className="mobile--carousel  ">
     {artistvideos !== null && artistvideos?.map((item,index) => (
       
       <div className="item">
         {/* <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]} /> */}
         <Link  to={{pathname:`/artist/${item.username}`, state:{artisti:props.item, modal:true }}}  link={props.link}>
           <div className="videoCard">
              <div className="video--container">
                  <video src={`https://api.starpokes.com/` + item?.path_url} loop autoPlay muted>

                  </video>
              </div>
              <div className="video--description">
                <p>{item?.artist_name}</p>
                {/* <p className="small">Musician</p> */}
              </div>
           </div>
           </Link>
       </div>
     ))}
       
     </div>
   </div>
 <div className="row d-none d-md-flex">
   {videosFormatted?.length !== 0 ? 
      <div className="col-lg-1 col-12">
      <div className="pos-relative d-none d-lg-block" >
        
        </div>
        <p className="bottoms-up">
          <label className="text-direct">Videos</label>

        </p>
  </div>
   
   : ''}

     
 
   <div className="col-md-10 custom--col videos">
     <div className="row">
     {videosFormatted !== null && videosFormatted?.map((item,index) => (
       <div className="col-md-3">
        <Link to={{pathname:`/artist/${item.username}`, state:{artisti:props.item, modal:true}}}  link={props.link}>
           <div className="videoCard">
              <div className="video--container">
                  <video src={`https://api.starpokes.com/` + item?.path_url} loop autoPlay muted>

                  </video>
              </div>
              <div className="video--description">
                <p>{item?.artist_name}</p>
                {/* <p className="small">Musician</p> */}
              </div>
           </div>
           </Link>
       </div>
     ))}
     </div>

   </div>
 </div>
</div> 


 {newcomers?.length !== 0 ?
   <div className="vip--container newcomers">
   <div className="carousel--container d-block d-md-none">
     {
       newcomers?.length !== 0 ? <div className="heading--text">Newcomers</div> : '' 
     }
     {/* <div className="heading--text">Newcomers</div> */}
     <div className="mobile--carousel ">
     {newcomers !== null && newcomers?.map((item,index) => (
       <div className="item">
         <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]} />

       </div>
     ))}
       
     </div>
   </div>
 <div className="row d-none d-md-flex">
   <div className="col-lg-1 col-12">
       <div className="pos-relative d-none d-lg-block" >
         
         </div>
         
         <p className="bottoms-up">
           <label className="text-direct">Newcomers</label>

         </p>
   </div>
     
 
   <div className="col-md-10 custom--col">
   
   <Carousel
   partialVisible={true}

   swipeable={isMobile ? true : false}
   draggable={false}

   responsive={responsive}
   ssr={true} // means to render carousel on server-side.
   infinite={true}
   // autoPlay={this.props.deviceType !== "mobile" ? true : false}
   autoPlay={false}
   autoPlaySpeed={5000}
   keyBoardControl={true}
   customTransition="all .5"
   transitionDuration={300}
   containerClass="carousel-container"
   removeArrowOnDeviceType={["tablet", "mobile"]}
     >
     {newcomers !== null && newcomers?.map((item,index) => (

           <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>

     ))}


     </Carousel>
   </div>
 </div>
</div> 
 : ''}


    {/* {Test here} */}


    {/* {End of test here} */}

    {
      trending?.length !== 0 ?
      <div className="vip--container">

<div className="carousel--container d-block d-md-none">
    <div className="heading--text">Trending</div>
    <div className="mobile--carousel ">
    {trending !== null && trending?.map((item,index) => (
      <div className="item">
        <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>

      </div>
    ))}
      
    </div>
  </div>

<div className="row d-none d-md-flex">

  <div className="col-lg-1 col-12">
      <div className="pos-relative d-none d-lg-block" >
        
        </div>
        <p className="bottoms-up">
          <label className="text-direct">Trending</label>
        </p>
  </div>
    

  <div className="col-md-10 custom--col">
  
  <Carousel
  partialVisible={true}
  swipeable={isMobile ? true : false}
  draggable={false}
  // showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlay={false}
  autoPlaySpeed={5000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={300}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
    >
    {trending !== null && trending?.map((item,index) => (
      

      <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>

    ))}
    </Carousel>
  </div>
</div>
</div>
      : ''
    }

    
 

    {/* { Here Starts Cat Show } */}

    <div className="vip--container reklama belowads">
      <div className="row">
        <div className="col-lg-1 col-12">
          {/* <div className="pos-relative">

          </div> */}
        </div>
      
          {
            belowads?.map((el, index) => {
              return(
                <div className="col">
                <HomeSingleAdd image={'https://api.starpokes.com/' + el?.path_url}/>

                </div>
              )
            })
          }
     
       
      </div>
    </div>



    { artists !== null && Object.keys(artists).map(function(key, index) {


      return(
          <>
        <div className="vip--container" key={index}>

        <div className="carousel--container d-block d-md-none">
          <div className="heading--text">{artists[key].category_name}</div>
          <div className="mobile--carousel ">
          {artists[key]?.artists !== null && artists[key]?.artists?.map((item,index) => (
            <div className="item">
              <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>

            </div>
          ))}
            
          </div>
        </div>

<div className="row singleCats d-none d-md-flex" key={index}>
            <div className="col-lg-1 col-12">
            <div className="pos-relative d-none d-lg-block">
              
              </div>
              <p className="bottoms-up" >
                <label className="text-direct">
                  <NavLink style={{color: "#cb2a6f"}} key={index}  to={{pathname:`/singleCategory/${artists[key].categoryid}`,state:{cat: artists[key], country:currentlocation}}}> 
                    {artists[key].category_name}
                    
                  </NavLink>
                </label>

              </p>
            </div>
            
        
          <div className="col-md-10 custom--col">
          <Carousel
             partialVisible={true}

             swipeable={isMobile ? true : false}
             draggable={false}
             // showDots={true}
             responsive={responsive}
             ssr={true} // means to render carousel on server-side.
             infinite={true}

             autoPlay={false}
             autoPlaySpeed={5000}
             keyBoardControl={true}
             customTransition="all .5"
             transitionDuration={300}
             containerClass="carousel-container"
             removeArrowOnDeviceType={["tablet", "mobile"]}
                  >
          {
                            // {items?.artists?.map((item) => {
            artists[key]?.artists.map(function(item, index) {
                return(
            
                <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item?.CategoryID[0]}/>
              
                )
              })
          }
            </Carousel>
          </div>
      </div>

      </div>

        {index % 3 === 0 ? <div className="vip--container reklama">
        <div className="row">
          <div className="col-md-1 col-12">
          </div>
          {
            middleads?.map((el, index) => {
              return(
                <div className="col">
                <HomeSingleAdd image={'https://api.starpokes.com/' + el?.path_url}/>
                </div>
              )
            })
          }
        </div>
      </div> : null }   
      </> 
      )
})
} 

<div className="vip--container reklama mt-3">
        <div className="row">
          {
            bottomads?.map((el, index) => {
              return(
                <div className="col offset-1">
                <a href={el?.url} target="_blank" rel="noopener noreferrer" >
                  <TwoAdds image={'https://api.starpokes.com/' + el?.path_url}/>
                </a>
              </div>
              )
            })
          }
        </div>
      </div>
    </div>
    
    
  );
  
};



export default connect(mapStateToProps,null)(Vipcard);
