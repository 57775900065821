// const data = []

const signinModalReducer = (state = false, action) => {
    switch(action.type){
        case 'SIGNINOPEN':
            return state = action.payload
        default: 
            return state;
    }
}

export default signinModalReducer;