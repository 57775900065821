import React, { useState, useEffect } from "react";
import axios from "../../axios";
import "./Categories.scss";
import { NavLink } from "react-router-dom";
import {categories} from "../../store/actions/index"
import {useDispatch, useSelector} from "react-redux"

const Categories = () => {

  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const perdoruesi = JSON.parse(tokeni);

  const getCats = useSelector(state => state.categories);

  useEffect(() => {
    // getAllCategories();

  },[getCats]);
  return (
      <div className='d-none d-lg-flex'>
    

            <div className="custom--container menu--container">
            {getCats !== null && getCats?.categories.map((item, index) => {
              return(

              <NavLink className="nav--icon nav--icon-2" key={index}  to={{pathname:`/singleCategory/${item.categoryid}`,state:{cat: item, country:perdoruesi?.useri?.user.countryCode}}}> 
                {item.category_name}
                <span className="link--border"></span>
              </NavLink>
              )
             })}
               
            </div>
      </div>
      
  );
};

export default Categories;
