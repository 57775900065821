import React,{useState, useEffect} from 'react'
import {useSelector} from 'react-redux'

import Vip from "../../VipCard/Vip/Vip"
import "../../VipCard/vipcard.scss"


import {user,translations, currentlocation, currentlanguage} from '../../../store/actions';

import axios from "../../../axios"

const queryString = require('query-string');


const SingleSubCategory = (props) => {

  const asdf = queryString.parse(props.location.search).name;


    let kategoria = props?.location.state.cat?.description
    let categoryid = props?.location.state.cat?.categoryid
    let subkategorite = props?.location.state.cat?.subcats


    const useri = useSelector((state) => state.user)
    const perkthimet = useSelector((state) => state.translations)
    const currentlang = useSelector((state) => state.currentlanguage)
    const lokacion = useSelector((state) => state.currentlocation)


    const [artists, setArtists] = useState([])




  useEffect(() => {

    axios.post(`api/all/web/artistatbycatid/${kategoria}/${currentlang}`,{countryCode:lokacion})
    .then(
      (res) => setArtists(res.data)
    )
    .catch((err) => console.log(err))

 
  }, []);

    return(
   

        <div className="custom__container vip--container">
          <div className="row">
            <div className="col-md-12">
            <h1>{kategoria}</h1>
            </div>
          </div>
          
          <div className="mobilesingle ">
            <div className="row">

                {artists !== null && artists?.map((item,index) => (
          // <LazyLoadComponent>
          // </LazyLoadComponent>
          <div className="col-6">
            <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>
          </div>

              ))}
            </div>
          </div>
        </div>
    )
}

export default SingleSubCategory