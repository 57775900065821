// const data = []

const currentlanguageReducer = (state = 'EN', action) => {
    switch(action.type){
        case 'CURRENTLANGUAGE':
            return state = action.payload
        default: 
            return state;
    }
}

export default currentlanguageReducer;