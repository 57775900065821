import React, {useState, useEffect} from 'react';
import './SignUp.scss';
import axios from '../../axios';
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = (props) => {

    

    let [sendingForm, setSendingForm] = useState(false);
    let [errorForm, setErrorForm] = useState("");
    const [errorMsg, setErrorMsg] = useState('Please fill in the fields')
  

    const handleForm = (event) => {
        setSendingForm(true);
        event.preventDefault();
        let data = {
            "name" : event.target.name?.value,
            "birthday" : event.target.day?.value + '/' + event.target.month?.value + '/' + event.target.year?.value,
            "gender" : "M",
            "email" : event.target.email?.value,
            "username" : event.target.username?.value,
            "password" : event.target.password?.value,
            "locationemail":"US",
            "countryCode":""
        }
        
        if(event.target.password.value == event.target.repassword.value){
            axios.post('/api/user/register',data)
            .then(res => {
                console.log(res);
                setSendingForm(false);
                let error = document.getElementById('error');
                error.innerHTML = "Registration Complete! Please check your email";

            })
            .catch(err => {
                console.log(err);
                setSendingForm(false);
                let error = document.getElementById('error');
                error.innerHTML = "Email already in use";
            })
        }else{
          
            setSendingForm(false);
        }

    }

    return(
        <div className="row no-gutters">
            <div className="col-md-6 leftside__picture">
                <div className="text__content">
                    <p>Make someone's day</p>
                    <div className="personalized">
                        <span>Personalized video shoutouts from their favorite people</span>
                    </div>
                </div>
            </div>
            <div className="col-md-6 ">
                {errorForm.length > 0 ? <p>{errorForm}</p>:null}
                <div className="rightside__container">
                    <div className="welcome__message">
                        <p>Sign Up</p>
                    </div>
                    <div className="becomevip__form">
                        <form onSubmit={handleForm}>
                            <div>
                                <input type="text" name="name" />
                                <span>Name</span>
                            </div>
                            <div classname="birthday__gender">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row birthday">
                                            <div className="col-12 mt-5">
                                            </div>
                                            <div className="col-md-3 pr-1">
                                                <input type="text" placeholder="Day" name="day" maxlength="2"/>
                                            </div>
                                            <div className="col-md-4">
                                                <input type="text" placeholder="Month" name="month" maxlength="2" />
                                            </div>
                                            <div className="col-md-5">
                                                <input type="text" placeholder="Year" name="year" maxlength="4"/>
                                            </div>
                                        </div>
                                        <span className="mb-1">Birthday</span>
                                    </div>
                                    <div className="col-md-6">
                                    <div class="form-group">
                                        <span class="checkbox checkbox-inline">
                                            <input type="radio" name="gender" id="fruit-apple" />
                                            <label for="fruit-apple">M</label>
                                        </span>
                                        <span class="checkbox checkbox-inline">
                                            <input type="radio" name="gender" id="fruit-lemon" />
                                            <label for="fruit-lemon">F</label>
                                        </span>
                                        <span class="checkbox checkbox-inline">
                                            <input type="radio" name="gender" id="fruit-pineapple" />
                                            <label for="fruit-pineapple">O</label>
                                        </span>
                                      
                                    </div>
                                        {/* <div className="row">
                                            <div className="col-12 mt-5">
                                            </div>
                                            <div className="col-md-4">
                                                <label>M <input type="radio" className="radio__button" name="gender"/></label>
                                            </div>
                                            <div className="col-md-4">
                                                <label>F<input type="radio" className="radio__button" name="gender"/></label>
                                            </div>
                                            <div className="col-md-4">
                                                <label>O <input type="radio" className="radio__button" name="gender"/></label>
                                            </div>
                                            <span className="mb-1">Gender</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <input type="text" name="email" />
                                <span>Email Address</span>
                            </div>
                            <div className="mt-4">
                                <input type="text" name="username"/>
                                <span>Username</span>
                            </div>
                            <div className="mt-4">
                                <input type="password" name="password" />
                                <span>Password</span>
                            </div>
                            <div className="mt-4">
                                <input type="password" name="repassword" />
                                <span>Password</span>
                            </div>
                            <div className="mt-4 terms">
                                <input type="checkbox" name="terms" className="terms--check"/><label>I have read and accept the Terms and Conditions and Privacy Policy</label>
                            </div>
                            
                            <div className="login__button">
                                { sendingForm ? 
                                    <ClipLoader
                                        size={150}
                                        color={"#123abc"}
                                        loading={sendingForm}
                                    />: <input type="submit" value="Send" />}
                                
                            </div>
                     
                        </form>
                   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;