import React from 'react';
import {
    Router,
    Route,
    Link,
    browserHistory,
    IndexRoute,
  } from "react-router-dom";
  import { LazyLoadImage } from 'react-lazy-load-image-component';
  import 'react-lazy-load-image-component/src/effects/blur.css';



const Vip = (props) => {
    return(
        <div key={props._id} item={props.item}>
            <Link to={{pathname:`/artist/${props.username}`,state:{artisti:props.item}}}  link={props.link}>
              <div className="row searchi">
                   <div className="col-md-4 col-4 singleartistsearch">
                   <img
                       src={
                         "https://api.starpokes.com/" + props.picturelocation
                       }
                     />
                   </div>
                   <div className="col-md-8 col-8 search--artistat">
                     <p>{props.name}</p>
                     <h6>{props?.CategoryID}</h6>
                   </div>
                 </div>           
            </Link>
        </div>
    )
}
export default Vip;