// const data = []

const artistVideosReducer = (state = null, action) => {
    switch(action.type){
        case 'ARTISTVIDEOS':
            return state = action.payload;
        default: 
            return state;
    }
}

export default artistVideosReducer;