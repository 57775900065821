import React,{useState, useEffect} from 'react'
import "./style.scss"
import Axios from "../../axios"

export default function Contact() {


    const [error, setError] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)



    function handleSubmit(e){
        e.preventDefault()
        setIsDisabled(true)
        let contactobject = {
            text:e.target.text.value,
            email: e.target.email.value,
            name: e.target.name.value
        }

        console.log('opa')

        Axios.post(`/api/contactus/contactus`, contactobject)
        .then(res => {
            setError('Message Successfully sent')
            setIsDisabled(false)
            setTimeout(function(){
                 setError('') 
                },3000);
        })
        .catch((err) => setError('Something went wrong, please try again!'))
    }

        useEffect(()=>{
            
    window.scrollTo({
        top: 50,
        behaviour: "smooth",
      });

        },[])

    return (
        <div className=" custom__container contact">
            <h1>Contact Us</h1>
            <h1>{error && error }</h1>
            <div className="contact--container">
                <form action="" onSubmit={handleSubmit}>

            
                <div className="row">
                    <div className="col-md-6">
                        <input type="text" placeholder="Name" name="name"/>
                    </div>
                    <div className="col-md-6" >
                        <input type="text" placeholder="Email" name="email"/>
                    </div>
                    <div className="col-md-12">
                        <textarea name="text" id="" cols="30" rows="10" placeholder={"Comment or Message"}>

                        </textarea>
                    </div>
                    <div className="col-md-12 text-center">
                        <button type="submit" disabled={isDisabled === true ? 'disabled' : ''}>Send</button>
                    </div>
                 
                </div>
                </form>
            </div>
        </div>
    )
}
