// const data = []

const userReducer = (state = null, action) => {
    switch(action.type){
        case 'GETUSER':
            return state = action.payload;
        default: 
            return state;
    }
}


export default userReducer;