import React, { useState, useEffect } from "react";
import "./Profile.scss";
import axios from "../../axios";
import "../../App.css";
import Edit from "./edit.png";
import { Link, useLocation } from "react-router-dom";
import SettingsLogo from "../../assets/settings.svg"

import Settings from "../Settings/Settings";

import Add from "../../Components/Adds/Adds"

import Addimage from "../../images/add2.jpg"

import { useSelector } from "react-redux";
import { user, picture, translations } from "../../store/actions";

import Vip from "../../Components/VipCard/Vip/Vip"

const Profile = (props) => {
  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const user = JSON.parse(tokeni);

  const location = useLocation();
  const useri = useSelector((state) => state.user);
  const picture = useSelector((state) => state.picture);
  const perkthimet = useSelector((state) => state.translations);

  //Destricture perkthimet
  const { lang } = perkthimet;

  // const id = query_param;
  const [getSingle, setSingleArtist] = useState(useri !== null && useri);
  const [getFavorite, setFavorite] = useState([]);

  const getSingleArtist = () => {
    axios
      .get(`api/user/by_username/${useri !== null && useri?.username}`,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
        },
      })
      .then((res) => {
        setSingleArtist(res.data[0]);
      })
      .catch((error) => console.log(error));
  };

  const getFavArtists = async () => {
    await axios
      .get(`/api/fansprofile/getfavoriteartists/${user?.useri?.user?._id}/english`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
        },
      })
      .then((res) => {
        setFavorite(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getSingleArtist();
    getFavArtists();
  }, [location]);

  return (
    <div className='custom__container p-0 main--container profile--mobile--container'>
      <div className='row'>
        <div className="col-12 mobile--profile--intro d-block  d-md-none">
          <div className="row">
          <div className="col-6 ">
                 
                    <p>{lang?.myProfile}</p>
                
                </div>
                <div className="col-6 text-right">
                      <p>
                      <Link to={{pathname:`/settings`,state:{artisti: useri !== null && useri}}}>
                        <img src={SettingsLogo} className="img-fluid" alt="" style={{height:"35px", width:"35px"}}/>
                      </Link>

                        </p>
                  </div>
          </div>
        </div>
        <div className='col-md-10'>
          <div className='row'>
            <div className='col-md-12 col-lg-3 profileimage text-center'>
           
                <div className='circle--shape'>
             
                    {picture?.image !== null && picture?.image.preview ? (
                      <>
                        <img src={picture?.image.preview} />
                      </>
                    ) : (
                      <>
                        <img src={picture?.image.real} alt='' />
                      </>
                    )}
               
                </div>
           
              <div className='profile--name'>
                <p>@{useri !== null && useri?.username}</p>
              </div>
            </div>
            <div className='col-md-12 col-lg-6 myprofile__form'>
              <div className="row d-none d-md-flex">
                <div className="col-md-6 col-lg-6">
                  <div className="use-gradient" style={{display:'inline-block'}}>
                    <p>{lang?.myProfile}</p>
                  </div>
                </div>
                <div className="col-md-6 text-right">
                      <p>
                      <Link to={{pathname:`/settings`,state:{artisti: useri !== null && useri}}}>{lang?.editprofile}</Link>

                        </p>
                  </div>
              </div>
              <form className='mt-5'>
                <div>
                  {/* <input type='text' value={useri !== null && useri?.username} /> */}

                  <div className='use-gradient'>
                    <p>{useri?.name}</p>
                  </div>
                  <span>{lang?.yourName}</span>
                </div>
                <div className='mt-4'>
                  {/* <input type='text' value={useri !== null && useri?.email} /> */}
                  <div className="use-gradient">
                    <p>{useri?.email}</p>
                  </div>
                  <span>{lang?.emailAddress}</span>
                </div>
              </form>
            </div>
            <div className='col-md-3'></div>
          </div>
          <div className='row'>
            <div className='col-md-12 favorite_celeb_title'>
              <p>{lang?.favoriteCelebrities}</p>
              <div class='row vip--container'>
                {Array.isArray(getFavorite)
                  ? getFavorite?.map((item) => (
                      // <div className='vip--card col-md-3' key={item._id}>
                      //   <div className='inner--vip'>
                      //     <Link to={user ? `/artist/${item.username}` : "#"}>
                      //       <img
                      //         src={`https://api.starpokes.com/${item.picturelocation}`}
                      //       />
                      //       <div className='vip--inner'>
                      //         <p>{item.name}</p>
                      //         <span>{item.CategoryID[0]}</span>
                      //       </div>
                      //     </Link>
                      //   </div>
                      // </div>
                      <div className="col-md-3 col-6">
                        <Vip item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>

                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-2 d-none d-lg-block'>
            <Add image={Addimage}/>
        </div>
      </div>
    </div>
  );
};

export default Profile;
