import React from "react";
// import Logo from "../"
import fb from "../../assets/facebook.png";
import ig from "../../assets/instagram.png";
import tw from "../../assets/twitter.png";
import lk from "../../assets/linkedin.png";
import { Link } from "react-router-dom";
import apple from "../../images/appstore.jpg";
import android from "../../images/google play.jpg"
import BecomeVIP from "../../Pages/BecomeVIP/BecomeVIP";
import Logo from "../../images/starpokes-logo.png";
import "./footer.scss";


import {useSelector} from "react-redux"

import { login, location, currentlanguage, translations } from "../../store/actions/index";


const Footer = () => {

  const perkthimet = useSelector((state) => state.translations)

  
  const {lang} = perkthimet || {};

  var d = new Date();
  var year = d.getFullYear();

  return (
    <div className="footer__container">
      {/* <div className=" banneri">
        <img src={frutomania} alt=""/>
      </div> */}
      <div className="custom__container">
        {/* <div className="row ">
          <div className="col-md-12 subscribe--footer">
            <p>Subscribe for Updates</p>
            <input type="email" placeholder="email"/><button>Submit</button>
          </div>
        </div> */}
        <div className="row footer--logo--icons">
          <div className="col-md-4 p-0">
            
            <img className="footer--image" src={Logo} alt="" />
          </div>
          <div className="col-md-4 offset-md-1 store">
              <a href="https://apps.apple.com/us/app/starpokes/id1523673747" target="_blank">
                <img src={apple} alt=""/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.harrisia.star_pokes_final" target="_blank">
              <img src={android} alt=""/>
              </a>
          </div>
          <div className="col-md-2 social--icons">
            <a href="https://www.facebook.com/starpokes.international"><img src={fb} alt=""/></a>
            <a href="https://www.instagram.com/starpokes.international/?hl=en"><img src={ig} alt=""/></a>
            <a href=""><img src={tw} alt=""/></a>
            <a href="https://www.linkedin.com/company/starpokes/"><img src={lk} alt=""/></a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 copyright">
            {/* <a href=""> {lang?.termsAndConditions} </a> */}
            <Link to="/settings/terms" >{lang?.termsAndConditions}</Link>
            <Link to="/settings/privacy" >{lang?.privacyPolicy}</Link>
            <Link to="/faq">Faq</Link>
            <Link to="/contact">Contact Us</Link>
            {/* <a href="">{lang?.privacyPolicy}</a> */}
            <a href="#" className="strpokes">&copy; {year}, Starpokes</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
