import React, { useState, useEffect } from "react";
import "./orders.scss";
import axios from "../../axios";
import Modal from "react-modal";

import Adds from "../Adds/Adds";
import Stories from "react-insta-stories";
import ReactPlayer from 'react-player'


import play from "../../images/video/play.svg"
import pause from "../../images/video/pause.svg"
import { Select } from "antd";

const { Option } = Select;

const Orders = () => {
  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const user = JSON.parse(tokeni);

  const [getOrders, setOrders] = useState([]);
  const [sentOrders, setSentOrders] = useState([])
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [videoState, setVideoState] = useState("");
  const [table, setTable] = useState('sent')
  const oneTime = 'You recieved a One Time Video!';
  const Downloadable = 'You recieved a Downloadable Video!';

  //handle select change
  function handleChange(value) {

    setTable(value)
  }

  //end of handle select change

  const [ orderDetails , setOrderDetails] = useState()

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "none",
      border:"none"
    },
  };

  const orderStyles = {
    content: {
      width:"100%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "none",
      border:"none"
    },
  };

  const getOrdersByUser = () => {
    axios
      // .get(`/api/orders/findallfansreq/${user?.useri.fan_id}`)
      .get(`/api/orders/users/all/${user?.useri.fan_id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
        },
      })
      .then((res) => {
        setOrders(res.data);
      })
      .catch((error) => console.log(error));
  };

  const getSentOrders = () => {
    axios.get(`api/orders/users/sent/all/${user?.useri.fan_id}`, {
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
      },
    })
    .then((res) => {
      setSentOrders(res.data);
    })
    .catch((error) => console.log(error))
  }

  let modalfunc = (url) => {
    setModalIsOpen(true);
    setVideoState("https://api.starpokes.com/" + url);
  };


  let modalInfo = (item) => {
    setOrderInfo(true);
    setOrderDetails(item)
  }


  const stories = [
    {
      url: videoState,
      type: "video",
    },
  ];

  useEffect(() => {
    getOrdersByUser();
    getSentOrders();
  
  }, []);


  const [videobutton, setVideoButton] = useState(pause)
  let player = document.getElementById('video-element')
  function playVideo()
{ 
if (player.paused){
  player.play(); 
  setVideoButton(pause)
} 
else{

  player.pause(); 
  setVideoButton(play)
} 

} 


const [ orderInfo, setOrderInfo ] = useState(false)





  return (
    <div className='orders__wrapper '>
      <div className='custom__container'>
        <div className='row intro--orders'>
          <div className='col-md-7'>
            <h1>Requests</h1>
          </div>
          <div className='col-md-2 text-right'>
            <div className='border-gradient border-gradient-purple'>
              <Select
                className='selectBoi'
                defaultValue='sent'
                onChange={handleChange}

              >
                <Option value='sent' selected>
                  Sent
                </Option>
                <Option value='recieved'>Recieved</Option>
              </Select>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
         {
           table === 'recieved' ? 
           <div className='custom--table row'>
           {getOrders?.map((item, key) => {
             return (
               <>
                  <div className="col-md-3">
                    <div className="carda border-gradient-purple card--gradient">
                      <div className="row">
                        <div className="col-4">
                        <img src={`https://api.starpokes.com/${item?.artistpicture}`} alt=""/>

                        </div>
                        <div className="col-8">
                          <p>{item?.ordertext}</p>
                          <p>{item?.artist_name}</p>
                          <div className="play">
                          <a onClick={() => modalfunc(item?.videourl)} className="float-left">
                           Play
                         </a>
                          {item?.isDownload === 0 ? '' : 
                         <a href={`https://api.starpokes.com/${item?.videourl}`} download target="_blank" className="float-right"> Download</a>
                         }
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
               </>
             );
           })}

    
       </div> :    <div className='row'>
                {sentOrders?.map((item, key) => {
               
                  return (
                    <>
                    {/* <div className='row ' key={key}>
                        <div className='col-md-12 columna '>
                          <div className='row'>
                            <div className='col-md-2 coli'>{item?.artist_name}</div>
                            <div className="col-md-3 coli">
                              {item?.isDownload === 0 ? 'View Once' : 'Downloadable Video'}
                            </div>
                            <div className='col coli'>{item?.ordertext}</div>
                            <div className='col-md-2 coli'>{item?.date}</div>
                            <div className="col-md-2 coli">Payment from: {item?.PaymentChannel}</div>
                        
                          </div>
                        </div>
                   
                </div> */}
               
                   <div className="col-md-3">
                    <div className="carda border-gradient-purple card--gradient">
                      <div className="row">
                        <div className="col-4">
                        <img src={`https://api.starpokes.com/${item?.fanpicture}`} alt=""/>

                        </div>
                        <div className="col-8">
                          <p>{item?.ordertext}</p>
                          <p>{item?.artist_name}</p>
                          <div className="play">
                          <a href="#" onClick={() => modalInfo(item)}> View Details</a>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                
                    </>
                  );
                })}

         
            </div>
         }
            <Modal
                style={customStyles}
                isOpen={ModalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                shouldCloseOnOverlayClick={() => setModalIsOpen(true)}
                // overlayClassName="overlay"
              >
                   <div
          onClick={() => setModalIsOpen(false)}
          style={{
            position: "absolute",
            right: "0",
            background: "none",
            border: "2px solid white",
            borderRadius: "40px",
            fontSize: "24px",
            height: "40px",
            width: "40px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          X
        </div>
         
          <div className="video--container">
            
          <video className="video" id='video-element' style={{width:"100%"}} autoPlay onEnded={() => setVideoButton(play)}>
            <source src={videoState} type="video/mp4" />
                   
            </video>
            <div className="controlat" onClick={playVideo} >
                <a href="#" id="btnPlayPause">
                        <img src={videobutton} alt=""/>
                </a>
              </div>
          </div>      
      <>
 
          </>
              </Modal>


              {/* //order Modal */}

              <Modal
                style={orderStyles}
                isOpen={orderInfo}
                onRequestClose={() => setOrderInfo(false)}
                shouldCloseOnOverlayClick={() => setOrderInfo(true)}
                // overlayClassName="overlay"
              >
                   <div
          onClick={() => setOrderInfo(false)}
          style={{
            position: "absolute",
            right: "0",
            background: "none",
            border: "2px solid white",
            borderRadius: "40px",
            fontSize: "24px",
            height: "40px",
            width: "40px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          X
        </div>    
      <>
          {/* {orderDetails} */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <table className="tabela" style={{background:"#f2f2f2"}}>
                  <thead>
                    <tr>
                      <th>Artist name</th>
                      <th>Shout type</th>
                      <th>Country</th>
                      <th>Currency</th>
                      <th>Date</th>
                      <th>Order text</th>
                      <th>Payment ID</th>
                      <th>Platform</th>
                      <th>Video is for</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>{orderDetails?.artist_name}</td>
                        <td>{orderDetails?.ShoutTypeID}</td>
                        <td>{orderDetails?.country}</td>
                        <td>{orderDetails?.currency}</td>
                        <td>{orderDetails?.date}</td>
                        <td>{orderDetails?.ordertext}</td>
                        <td>{orderDetails?.paymentId}</td>
                        <td>{orderDetails?.platform}</td>
                        <td>{orderDetails?.videoIsFor}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </>
              </Modal>
          </div>

          <div className='col-md-2 offset-md-1 '>
            <Adds />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
