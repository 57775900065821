import counterReducer from './counter';
import loginReducer from './isLogged';
import artistsReducer from "./artists"
import newcomersReducer from "./newcomers"
import trendingReducer from "./trending"
import pictureReducer from "./picture"
import categoriesReducer from "./categories"
import userReducer from "./user"
import currencyReducer from "./currency"
import languageReducer from "./language"
import locationReducer from "./locations"
import currentlocationReducer from "./currentlocation"
import {combineReducers} from 'redux';
import translationsReducer from "./translations"
import currentlanguageReducer from "./currentlanguage"

import signinModalReducer from "./modalreducers/signin"
import loginModalReducer from "./modalreducers/login"
import forgotModalReducer from "./modalreducers/forgot"
import artistVideosReducer from './artistvideos';
import sidebarReducer from "./sidebarads"
import bottomReducer from "./bottomads"
import middleReducer from "./middleads"
import belowReducer from "./belowVideo"



const allRedurces = combineReducers({
    counter: counterReducer,
    login: loginReducer,
    artists: artistsReducer,
    user:userReducer,
    newcomers: newcomersReducer,
    trending:trendingReducer,
    picture:pictureReducer,
    categories:categoriesReducer,
    currency:currencyReducer,
    language:languageReducer,
    location:locationReducer,
    currentlocation: currentlocationReducer,
    translations:translationsReducer,
    currentlanguage:currentlanguageReducer,
    loginmodal:loginModalReducer,
    signinmodal:signinModalReducer,
    forgotmodal:forgotModalReducer,
    artistvideos:artistVideosReducer,
    sidebarads: sidebarReducer,
    bottomads: bottomReducer,
    middleads: middleReducer,
    belowads:belowReducer
})


export default allRedurces;

