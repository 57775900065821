import Axios from "axios";

let user = localStorage.getItem("user");

let userToken = JSON.parse(user)


const axiosInstance = Axios.create({
  // baseURL: "http://localhost:5000/",
  baseURL: "https://api.starpokes.com/",
  // baseURL: "https://testapi.starpokes.com/",
  headers: {
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
  }
});

// axiosInstance.interceptors.response.useW(function (response) {
//   if(response.data.message === "Unauthorized") {
//     localStorage.clear();
//     window.location.href = "/login";
//   }
//   return response;
// }, function (error) {
//   return Promise.reject(error);
// });

export default axiosInstance;