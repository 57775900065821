import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "normalize.css"
import 'antd/dist/antd.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";


//redux
import {createStore} from 'redux';
import allReducers from './store/reducers';
import {Provider} from 'react-redux';
import {persistStore, persistReducer} from "redux-persist"


// import store from "./store/store";
import storage from "redux-persist/lib/storage"

import { PersistGate } from 'redux-persist/integration/react'


const persistConfig = {
  key:"root",
  storage,
}
const persistedReducer = persistReducer(persistConfig, allReducers)



const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  let persistor = persistStore(store)
  

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,

 

  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>  
      <Suspense fallback={(<div>Loading ~~~</div>)} >
        <App />
      </Suspense>
      </PersistGate>
    </Provider>, document.getElementById('root')
);
  // document.getElementById("root")


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
