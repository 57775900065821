// const data = []

const forgotModalReducer = (state = false, action) => {
    switch(action.type){
        case 'FORGOTOPEN':
            return state = action.payload
        default: 
            return state;
    }
}

export default forgotModalReducer;