import React, { useState, useEffect } from "react";
import axios from "../../../../axios";

import { Select } from 'antd';



import {useDispatch, useSelector} from 'react-redux';

import {user, location, currentlocation,currentlanguage} from '../../../../store/actions/index';



const Language = () => {

  const dispatch = useDispatch()
  
  const perkthimet = useSelector(state => state.translations)
  const user = useSelector((state)=> state.user)
  const [getLocation, setLocation] = useState();
  const [current, setCurrent] = useState(false);
  const { Option } = Select;

  const [updated, setIsUpdated] = useState(false)
  const [msg, setMsg] = useState('')
  const [languageChanged, setLanguage] = useState(user?.language)





  const {language, lang} = perkthimet
  const getUserLocation = () => {
    axios
      .get(`/api/languages/getalllang`)
      .then((res) => {
        setLocation(res.data);
      })
      .catch((error) => console.log(error));
  };
  function onChange(value) {
    // setCurrent(value);
    setLanguage(value)
  }
  function onSearch(val) {
    setCurrent(val);
  }

  const changeLanguage = (e) => {

    dispatch(currentlanguage(current))
  }


  const submitForm = (event) => {
    event.preventDefault()

    console.log(languageChanged)

    let config = {
        language: languageChanged,
      };

    // if (!user.name || !user.username) return

    // props.addUser(user)
    // setUser(useri)
    dispatch(currentlanguage(languageChanged))


    axios.put(`api/user/fansat/${user?._id}`, config).then(res => {

        setIsUpdated(true);
    })
 
    setMsg('Profile updated successfully')
    setTimeout(function(){ 
        setMsg('')
     }, 6000);

  }

  useEffect(() => {
    getUserLocation();
  }, []);
 
  return (
    <div className="container edit-profile">
      <div className="row">
        <div className="col-md-12">
          <h1>{lang?.language}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p>{lang?.changeLanguage}</p>
     
      <form action="" onSubmit={submitForm}>
          <div className="use-gradient" style={{display:"inline-block"}}>

    
        <Select
            showSearch
            style={{ width: 400 }}
            placeholder={language}
            optionFilterProp="children"
            defaultValue={language}
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            >
           
            {getLocation?.map((item, index) => (
              <Option key={index} value={item.code}>{item.language}</Option>
              ))}
          </Select>
          </div>
          <button onClick={changeLanguage} type="submit" style={{marginTop: "20px"}}>Update Language</button>
          </form>
      
        </div>

       

      </div>
    </div>
  );
};

export default Language;
