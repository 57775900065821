import React from "react";
import Banner from "../../Components/Banner/Banner";
import "./homepage.scss";
import Vipcard from "../../Components/VipCard/VipCard";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Homeadds from '../../Components/Adds/HomeAdds'

import {useSelector} from "react-redux"

import { login, location, currentlanguage, translations } from "../../store/actions/index";


// import store from "./redux/store";

// import {fetchPosts} from './redux/actions'

// store.dispatch(fetchPosts())

const Homepage = (props) => {

  const perkthimet = useSelector((state) => state.translations)

 
  
  // const {lang} = perkthimet


  return (
    <div className="container-fluid p-0">
      <Banner lang={perkthimet?.lang}/>

      <div className="content container-fluid">
        <div className="p-0">
          <div className="row">
            <div className="col-md-10">
              <Vipcard lang={perkthimet?.lang}></Vipcard>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Homeadds />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
