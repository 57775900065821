import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { Link } from "react-router-dom";
import Vip from "./Vip";
import { useSelector, useDispatch } from "react-redux"

import {user, picture, login, translations} from '../../../store/actions';


const Searchbox = (props) => {

  const [isFocused, setIsFocused] = useState(false);
  const [artists, setArtists] = useState(null);
  const [artistsToShow, setArtistsToShow] = useState(null);


  const perkthimet = useSelector(state => state.translations)

 
  const focused = async () => {
    try {
      let artistat = await axios.get(`/api/all/artistat/${perkthimet?.language}`);
      if (artists == null) {
      
        setArtists(artistat.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsFocused(true);
  };

  const filterArtists = (event) => {
    const newArr = [];
    if (artists?.length > 0 && event.target.value?.length > 0) {
      artists.map((element, key) => {
        return element.name
          .toLowerCase()
          .startsWith(event.target.value.toLowerCase())
          ? newArr.push(element)
          : null;
      });
    }
    setArtistsToShow(newArr);
  };


  const focusedOut = (event) => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };
    return(
    <>
    <div className='search-container' tabIndex='1'>
      <div className="input-box">
        <input
          type="text"
          onFocus={focused}
          onBlur={focusedOut}
          onChange={filterArtists}
          placeholder="Search Artists"
         
      />
      </div>

            
    </div>
  

    {/* <div className="form-group has-search">
    <span className="fa fa-search form-control-feedback"></span>
    <input type="text" 
            className="form-control" 
                onFocus={focused}
                onBlur={focusedOut}
                onChange={filterArtists}/>
        </div> */}
     
     <div className="searchbox">
       {isFocused
         ? artistsToShow?.map((item, index) => {
             return (
                <div className="vipi">
                  <Vip item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>
                </div>
            
             );
           })
         : null}
         {/* {
      artistsToShow?.map((item, index) => {
             return (
      
              <Vip item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>
            
             );
           })
          } */}
     </div>
  </>
    )
}

export default Searchbox;