import React, { useState, useEffect } from "react";
import renderHTML from 'react-render-html';

import axios from "../../../../axios";

const Terms = () => {


  const [terms, setTerms] = useState()

  useEffect(() => {
    window.scrollTo({
      top: 50,
      behaviour: "smooth",
    });
    
    axios.get(`api/terms/getterms/mobile/fan`).then((res) => {
      setTerms(res.data.content)
    })
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
        <h1>Terms & Conditions</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
        {terms && renderHTML(terms)}
        </div>
      </div>
    </div>
  );
};

export default Terms;
