// const data = []

const loginModalReducer = (state = false, action) => {
    switch(action.type){
        case 'LOGINOPEN':
            return state = action.payload
        default: 
            return state;
    }
}

export default loginModalReducer;