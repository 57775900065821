import React, { useState, useEffect, useRef } from "react";
import axios from "../../axios";
import "./artist.scss";
import { useLocation, Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from "react-modal";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import Heart from "../../assets/heart.png";
import HeartFill from "../../assets/heart-fill.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { css } from "@emotion/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import play from "../../images/video/play.svg";
import pause from "../../images/video/pause.svg";

import Clock from "../../assets/clock.svg"

import HomeAdds from "../Adds/HomeAdds";
import Vip from "../VipCard/Vip/Vip";
import Stories from "react-insta-stories";
import Login from "../PopUps/Login/Login";

import ScaleLoader from 'react-spinners/ScaleLoader';

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


// const id = queryString.parse(props.location.search)._id;
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { user, currency,translations } from "../../store/actions/index";
import FormDownloadableOther from "./FormDownloadableOther";
import FormDownloadableSelf from "./FormDownloadableSelf";

const useForceUpdate = () => useState()[1];

const Artist = (props) => {
  console.log(props, 'propsat')

  const forceUpdate = useForceUpdate();

  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const perdoruesi = JSON.parse(tokeni);

  const login = useRef(null);

  const location = useLocation();

  const { artist } = useParams();

  const reduxUser = useSelector((state) => state.user);
  const currencia = useSelector((state) => state.currency);
  const perkthimet = useSelector((state) => state.translations);



  const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

`;
let [color, setColor] = useState("#cb2a6f");



  const [getSingle, setSingleArtist] = useState([]);
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [nondlmodalopen, setNonDlModalOpen] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [someoneVideo, setSomeoneVideo] = useState(false);
  const [isDownload, setIsDownload] = useState(null);
  const [selected, setSelected] = useState(false);
  const [relatedArtist, setRelatedArtist] = useState([]);
  const [welcomeVideo, setWelcomeVideo] = useState('')
  const [isAvailable, setIsAvailable] = useState();

  const [onetime, isOneTime] = useState(false)

  const [Loading, setLoading] = useState(true);

  const [currencyData, setCurrencyData] = useState();

  //OrderStatus Message

  const [statusMessage, setStatusMessage] = useState([]);

  //End of order status message

  const closeModalFromChild = () => {
    setModalIsOpen(false);
  };

  const setOrderType = () => {
    otherRequest(true);
  };

  const orderStatus = (statusi) => {
    setStatusModal(true);

    // setStatusMessage(statusi)

    setStatusMessage(statusi);
    setTimeout(function () {
      if (statusi?.paymentstatus === true) {
        props.history.replace("/orders");
      }
      setStatusModal(false);
    }, 3000);
  };

  //Video hooks

  let Artisti = props?.location?.state?.artisti;



  // Artisti = getSingle;

  // let Artisti = getSingle;

  if (Artisti === undefined) {
    Artisti = getSingle;


  }


  const [videos, setVideos] = useState([]);

  const getVidoes = () => {
    axios
    .get(`api/all/getthreelastvideos/${Artisti?.artist_id}`)
    .then((res) => {
      setVideos(res.data);
    });
 
  };

  const getSingleArtist = async (currencycode) => {
    try {
      axios
        .get(`api/artist/by_username/web/${artist}`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
          },
        })
        .then((res) => {
          setSingleArtist(res.data[0]);
          setLoading(false);

          setWelcomeVideo(res?.data[0]?.WellcomeVideo);
  

      

          console.log(res.data[0]._id, 'data')
          //Perfundon pjesa e related artists

          console.log(Artisti?._id)
         
          if (reduxUser !== null) {
            axios
              .post(`api/artist/artistat/${res.data[0]?._id}/${perkthimet?.language}`, {
                toCurrency: reduxUser?.currency,
              })
              .then((res) => 
              {
                setCurrencyData(res.data)
                setLoading(false);
              }
              );
          } else if(Artisti !== undefined || Artisti !== null) {
            axios
              .post(`api/all/artistat/${res.data[0]?._id}/${perkthimet?.language}`, {
                toCurrency: currencia?.currency,
              })
              .then((res) => 
             { setCurrencyData(res.data)
              setLoading(false);
            }
              );
          }
         

          if (
            perdoruesi?.useri?.user?.favorite_artists.includes(res.data[0]._id)
          ) {
            setSelected(true);
          }

          axios
            .post(`api/artist/artistat/${res.data[0]._id}/${perkthimet?.language}`, {
              toCurrency: "USD",
            })
            .then((res) => {
              setSingleArtist(res.data);
           
            });
        })
        .catch((error) => console.log(error));
    } catch (err) {
      console.log(err);
    }
  };

  // const getMusicArtists = () => {
  //   axios
  //     .post(`api/all/artistatbycatid/${Artisti.CategoryID[0]}`, {
  //       CountryCode: Artisti.countryCode,
  //     })
  //     .then((res) => {
  //       const tempRelated = res.data;

  //     })
  //     .catch((error) => console.log(error));
  // };


  // console.log(Artisti?.CategoryID[0], 'kategoria')

 
  // getMusicArtists();
  const addFavoriteArtist = () => {
    setSelected(!selected);
    if (!selected) {
      axios
        .post("api/fansprofile/addfavoriteartist", {
          user_id: perdoruesi?.useri.id,
          artist_id: Artisti?._id,
        })
        .then(function (response) {})
        .catch(function (error) {});

      let new__favoriteartist__array = [
        ...perdoruesi?.useri?.user?.favorite_artists,
        getSingle?._id,
      ];
      let new_object_user = { ...perdoruesi };
      new_object_user.useri.user.favorite_artists = new__favoriteartist__array;

      window.localStorage.setItem("user", JSON.stringify(new_object_user));
    } else {
      axios
        .post("api/fansprofile/removefavoriteartist", {
          user_id: perdoruesi?.useri.id,
          artist_id: getSingle?._id,
        })
        .then(function (response) {})
        .catch(function (error) {});
      let new__favoriteartist__array = [
        ...perdoruesi?.useri?.user?.favorite_artists,
      ];
      let new_object_user = { ...perdoruesi };
      const index = new__favoriteartist__array.indexOf(getSingle?._id);
      if (index > -1) {
        new__favoriteartist__array.splice(index, 1);
      }
      new_object_user.useri.user.favorite_artists = new__favoriteartist__array;
      // console.log(typeof(new_object_user));
      window.localStorage.setItem("user", JSON.stringify(new_object_user));
    }
  };

  const orderVideo = () => {
    if (perdoruesi) {
      setModalIsOpen(true);
    } else {
      // props.history.push("/login");
      login.current.open();
    }
  };

  const orderNonDownloadableVideo = () => {
    if (perdoruesi) {
      // isOneTime(true)
     
      setNonDlModalOpen(true);
    } else {
      // props.history.push("/login");
      login.current.open();
    }
  }

  const openVideo = () => {
    setVideoModal(true);
  };

  const storyStyles = {
    content: {
      top: "50%",
      left: "50%",
      // right: "auto",
      // bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "transparent",
      width: "470px",
      minHeight: "570px",
      position: "absolute",
      border: "none",
    },
  };

  const videoStyles = {
    content: {
      top: "50%",
      left: "50%",
      // right: "auto",
      // bottom: "auto",
      marginRight: "0",
      transform: "translate(-50%, -50%)",
      background: "transparent",
      width: "700",
      minHeight: "100%",
      position: "absolute",
      borderRadius: "0",
      border: "none",

      // overflow: "hidden",
      // marginTop: "35px",
      // marginBottom: "35px"
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      // right: "auto",
      // bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#2F355A",
      width: "700px",
      minHeight: "99%",
      position: "absolute",
      borderRadius: "50px",
      border: "none",

      // overflow: "hidden",
      // marginTop: "35px",
      // marginBottom: "35px"
    },
  };
  const imageStyles = {
    content: {
      height: "100px",
      width: "100px;",
      borderRadius: "50%",
    },
  };

  const otherRequest = (reqcon) => {
    setSomeoneVideo(reqcon);
  };

  // const setupCurrency = async () => {
  //   if (reduxUser !== null) {
  //     axios
  //       .post(`api/all/artistat/${Artisti?._id}/${perkthimet?.language}`, {
  //         toCurrency: reduxUser?.currency,
  //       })
  //       .then((res) => setCurrencyData(res.data));
  //   } else if(Artisti !== undefined || Artisti !== null) {
  //     axios
  //       .post(`api/all/artistat/${getSingle?._id}/${perkthimet?.language}`, {
  //         toCurrency: currencia?.currency,
  //       })
  //       .then((res) => setCurrencyData(res.data));
  //   }
  // };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 55
    },
    tablet: {
      breakpoint: { max: 1024, min: 621 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 620, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 30
    }
  };

  const stories = [
    {
      url: `https://api.starpokes.com/${welcomeVideo}`,
      type: "video",
    },
  ];

  //butoni i videos

  const [videoState, setVideoState] = useState("");

  const [videoModalOther, setVideoModalOpen] = useState(false);

  let modalfunc = (url) => {
    setVideoModalOpen(true);
    setVideoState("https://api.starpokes.com/" + url);
  };
  function closeModal() {
    setVideoModalOpen(false);
  }



  function getAll() {

    

    setSelected(false);
    getSingleArtist();
    getVidoes();
    // getRelatedArtist();
    
    

    
  }

  console.log(Loading, 'loading')
  
  const [videobutton, setVideoButton] = useState(pause);
  

  function playVideo(e) {
  let player = document.getElementById("video-element");
    e.preventDefault();
    if (player.paused) {
      player.play();
      setVideoButton(pause);
    } else {
      player.pause();
      setVideoButton(play);
    }
  }
  window.scrollTo({
    top: 50,
    behaviour: "smooth",
  });

  // setupCurrency();

  useEffect(() => {
    // setupCurrency();

    // setModalIsOpen(true);
    // getMusicArtists();
    // getMusicArtists();


    setLoading(true)

    axios.get(`api/artist/by_username/web/${artist}`)
    .then(res => {
      console.log(res.data, 'errli dita')
      console.log(res?.data[0]?.temp_unavailable, 'oooo')
      setIsAvailable(res?.data[0]?.temp_unavailable);

      
      axios
      .post(`api/artist/web/related`, {
        artistid: res.data[0].artist_id,
      })
      .then((res) => {
        setRelatedArtist(res.data);
        // setLoading(false);
      });
    })
    .catch(err => {
      console.log('jo jo ti jo')
    })

    window.scrollTo({
      top: 50,
      behaviour: "smooth",
    });
    // setupCurrency();
    // setTimeout(function(){ 
    
    //  }, 3000);
    

    getAll();

       axios
    .get(`api/all/getthreelastvideos/${Artisti?.artist_id}`)
    .then((res) => {
      setVideos(res.data);
    });
  }, [location,Artisti?.WellcomeVideo]);


  console.log(isAvailable,' is it?')



  //  Artisti = getSingle;

  return Loading === true ? 
  <div className="scale--container">
     <ScaleLoader color={color} css={override} loading={Loading} height='90' width='10' size={150} />
  </div>
:  (
    <div className='profile--wrapper'>

      <Login ref={login}></Login>
      <div className='custom__container'>
        <div className='row'>
          <div className='col-md-10'>
            <div className='row'>
              <div className='col-md-11 offset-md-1 col-12'>
                <div className='row'>
                  <div className='col-md-12 col-lg-4'>
                
                    <div
                      className='profile--image--container'
                      onClick={Artisti?.WellcomeVideo?.length > 10 ? openVideo : ''}
                      style={ Artisti?.WellcomeVideo?.length > 10 ? {display : ''} : { backgroundImage:'none'}} 
                    >
                      <img
                        src={`https://api.starpokes.com/${Artisti?.picturelocation}`}
                        alt=''
                        draggable="false"
                        className={Artisti?.WellcomeVideo?.length > 10 ? 'd-none d-md-inline-block' : ''} 
                      />
                      {/* <video autoPlay muted className="d-block d-md-none" src={`https://api.starpokes.com/${Artisti?.WellcomeVideo}`}>
                    
                      </video> */}
                      <Video 
                          autoPlay  
                          muted
                          loop
                          controls={false}
                          poster={`https://api.starpokes.com/${Artisti?.picturelocation}`}
                          className="d-block d-md-none"
                          onCanPlayThrough={() => {
                              // Do stuff
                          }}
                          >
                          <source src={`https://api.starpokes.com/${Artisti?.WellcomeVideo}`} type="video/webm" />
                      </Video>

                    </div>
                    <div className='profile--image--text'>
                      <p> <span><img src={Clock} className="img-fluid" alt="" style={{height:"25px", width:"18px"}} /></span> {Artisti?.responsetimehh} {perkthimet?.lang?.hours}</p>

                      <span>{perkthimet?.lang?.responseTime}</span>
                    </div>
                  </div>
                  <div className='col-lg-7 col-md-12 artist--info--wrapper'>
                    <div className='artist--info'>
                      <div className='row'>
                        <div className='col artist--name'>
                          <h1 className=''>{Artisti?.name}</h1>
                          {perdoruesi ? (
                            <img
                              src={selected ? HeartFill : Heart}
                              onClick={() => addFavoriteArtist()}
                              alt=''
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className='artist__cats'>
                        <ul>
                          {Artisti?.CategoryID?.map((item,index) => {
                            // setCategory(item)
                            return <li key={index}>{item}</li>;
                          })}
                        </ul>
                      </div>
                      <div className='mobile--responsetime d-block d-md-none'>
                      <p> <span><img src={Clock} className="img-fluid" alt="" style={{height:"25px", width:"18px"}} /></span> {Artisti?.responsetimehh} Hours</p>


                        <span>Response Time</span>
                      </div>
                      <div className='artist-bio'>
                        <p>{Artisti?.biography}</p>
                      </div>

                      {isAvailable === true ? <h5 style={{color :"#cb2a6f"}}>This Artist is currently unavailable</h5> 
                      : <div className='row downloadable'>
                      {/* <div className='col-md-6'>
                        <h5>
                          {perkthimet?.lang?.downloadableVideo} -{" "}
                          {reduxUser
                            ? reduxUser?.currency
                            : currencia?.currency}{" "}
                          {currencyData?.price_withDownload?.toFixed(2)}
                        </h5>
                        <div
                          className='gradient'
                          onClick={() => orderVideo()}
                        >
                          <p>{perkthimet?.lang?.bookNow}</p>
                        </div>
                      </div> */}
                      <div className='col-12 col-md-8 offset-md-2'>
                        <h5>
                          {perkthimet?.lang?.oneTimeVideo} -{" "}
                          {reduxUser
                            ? reduxUser?.currency
                            : currencia?.currency}{" "}
                          {currencyData?.price_withoutDownload
                            ? currencyData?.price_withoutDownload?.toFixed(2)
                            : Artist?.currency}
                        </h5>
                        <div
                          className='gradient'
                          onClick={() => orderNonDownloadableVideo()}
                        >
                          <p>{perkthimet?.lang?.bookNow}</p>
                        </div>
                      </div>
                    </div>
                    }



                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {Related Posts starts here} */}

            {videos.length !== 0 ? (
              <div className='videos--container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h1>Star Videos</h1>
                  </div>
                  {videos?.map((item,index) => (
                    <>
                      <div
                        className='col-md-3 video-container'
                        onClick={() => modalfunc(item?.videourl)}
                        key={index}
                      >
                  
                     
                          <video
                            className='video'
                            style={{ width: "100%", opacity: "0.6" }}
                            autoplay
                            onEnded={() => setVideoButton(play)}
                            src={`https://api.starpokes.com/${item.videourl}`}
                            type='video/mp4'
                          >
                            {/* <source
                           
                            /> */}
                          </video>
                      
                      </div>
                    </>
                  ))}
                </div>
              </div>
            ) : null}
            <div className='row'>
              <div className='col-md-12'>
                <div className='vip--container new__comers'>
                <div className="carousel--container d-block d-lg-none">
                    <div className="heading--text">Related</div>
                    <div className="mobile--carousel ">
                    {relatedArtist !== null && relatedArtist?.map((item,index) => (
                      <div className="item">
                        <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]}/>

                      </div>
                    ))}
                      
                    </div>
                  </div>

                  <div className="related-carousel d-none d-lg-block">
                    <div className='row'>
                      <div className='col-md-12'>
                        <h1>Related</h1>
                      </div>
                    </div>
                  {/* {Carouseli starts here} */}
                  <div className=''>
                  <Carousel
                  partialVisible={true}

                  swipeable={isMobile ? true : false}
                  draggable={false}
 
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={5000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={300}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}

                    >
                    {relatedArtist !== null && relatedArtist?.map((item,index) => (
                // <LazyLoadComponent>
                // </LazyLoadComponent>
                          <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={item.CategoryID[0]} cat={Artisti?.CategoryID[0]}/>

                    ))}


                    </Carousel>
                  </div>
                  {/* {Carousel ends here} */}
                </div>
              </div>
            </div>
            {/* {End Of Related Posts} */}
                  </div>

          </div>

          <div className='col-md-2 d-none d-md-block'>
            <HomeAdds />
          </div>
        </div>
      </div>
      {/* Downloadable Modal */}
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        shouldCloseOnOverlayClick={() => setModalIsOpen(false)}
        style={customStyles}
        // overlayClassName="Overlay"
      >
        <div className='container'>
          {/* <button>X</button> */}
          <div className="row">
            <div className="col-12 text-right">
            <svg viewBox="0 0 100 110" width="40" height="35" onClick={() => setModalIsOpen(false)} style={{cursor:"pointer"}}>
                            <line x1="10" y1="10" x2="100" y2="100" stroke="white" strokeWidth="15" />
                            <line x1="100" y1="10" x2="10" y2="100" stroke="white" strokeWidth="15" />
                        </svg>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='modali'>
                <div className='row order--main--info'>
                  <div className='col-12'>
                    {/* <img
                    src={`https://api.starpokes.com/${Artisti?.picturelocation}`}
                    alt=''
                    style={imageStyles}
                  /> */}

                    <LazyLoadImage
                      src={`https://api.starpokes.com/${Artisti?.picturelocation}`}
                      alt=''
                      style={imageStyles}
                    />
                    <span className='modali-title'>
                      Booking - {Artisti?.name}
                    </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <p className='paragraph'>
                    {perkthimet?.lang?.isThisVideoForSomeOneElse}
                    </p>
                  </div>

                  <div className='col-md-4 text-right check-boxat'>
                    <div className='form-group'>
                      <span className=''>
                        <input
                          type='radio'
                          id='someonevideo'
                          name='someonevideo'
                          onChange={() => otherRequest(true)}
                        />

                        <label htmlFor='someonevideo'>{perkthimet?.lang?.yes} </label>
                      </span>
                      <span className=' ml-3'>
                        <input
                          type='radio'
                          id='someoneno'
                          name='someonevideo'
                          onChange={() => otherRequest(false)}
                          checked={someoneVideo === false ? "checked" : null}
                        />
                        <label htmlFor='someoneno'>{perkthimet?.lang?.no} </label>
                      </span>
                    </div>
                  </div>
                </div>
                  {
                    someoneVideo ? (
                      <FormDownloadableOther
                        downloadable={1}
                        artistid={getSingle?.artist_id}
                        artistname={getSingle?.name}
                        amount={getSingle?.price_withDownload}
                        currency={reduxUser?.currency}
                        user={reduxUser}
                        closeModalFromChild={() =>
                          closeModalFromChild(setModalIsOpen(false))
                        }
                        orderStatus={(param) => orderStatus(param)}
                      />
                    ) : (
                      <FormDownloadableSelf
                        downloadable={1}
                        artistid={getSingle?.artist_id}
                        artistname={getSingle?.name}
                        amount={getSingle?.price_withDownload}
                        currency={reduxUser?.currency}
                        user={reduxUser}                        
                        closeModalFromChild={() =>
                          closeModalFromChild(setModalIsOpen(false))
                        }
                        setOrderType={() => setOrderType(otherRequest(true))}
                        orderStatus={(param) => orderStatus(param)}
                      />
                    )
                  }
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END OF DL MODAL */}

      {/* NON DL MODAL */}
      <Modal
        isOpen={nondlmodalopen}
        onRequestClose={() => setNonDlModalOpen(false)}
        shouldCloseOnOverlayClick={() => setNonDlModalOpen(false)}
        style={customStyles}
        // overlayClassName="Overlay"
      >
        <div className='container'>
        <div className="row">
            <div className="col-12 text-right">
            <svg viewBox="0 0 100 110" width="40" height="35" onClick={() => setNonDlModalOpen(false)} style={{cursor:"pointer"}}>
                            <line x1="10" y1="10" x2="100" y2="100" stroke="white" strokeWidth="15" />
                            <line x1="100" y1="10" x2="10" y2="100" stroke="white" strokeWidth="15" />
                        </svg>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='modali'>
                <div className='row order--main--info'>
                  <div className='col-12'>
                    {/* <img
                    src={`https://api.starpokes.com/${Artisti?.picturelocation}`}
                    alt=''
                    style={imageStyles}
                  /> */}

                    <LazyLoadImage
                      src={`https://api.starpokes.com/${Artisti?.picturelocation}`}
                      alt=''
                      style={imageStyles}
                    />
                    <span className='modali-title'>
                      Booking - {Artisti?.name}
                    </span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-8'>
                    <p className='paragraph'>
                      {/* Is this video for someone else ? */}
                      {perkthimet?.lang?.isThisVideoForSomeOneElse}
                    </p>
                  </div>

                  <div className='col-md-4 text-right check-boxat'>
                    <div className='form-group'>
                      <span className=''>
                        <input
                          type='radio'
                          id='someonevideo'
                          name='someonevideo'
                          onChange={() => otherRequest(true)}
                        />

                        <label htmlFor='someonevideo'>{perkthimet?.lang?.yes} </label>
                      </span>
                      <span className=' ml-3'>
                        <input
                          type='radio'
                          id='someoneno'
                          name='someonevideo'
                          onChange={() => otherRequest(false)}
                          checked={someoneVideo === false ? "checked" : null}
                        />
                        <label htmlFor='someoneno'>{perkthimet?.lang?.no} </label>
                      </span>
                    </div>
                  </div>
                </div>
                  {
                    someoneVideo ? (
                      <FormOne
                        downloadable={isDownload}
                        artistid={getSingle?.artist_id}
                        artistname={getSingle?.name}
                        amount={getSingle?.price_withoutDownload}
                        currency={reduxUser?.currency}
                        user={reduxUser}
                        onetime={'true'}
                        closeModalFromChild={() =>
                          closeModalFromChild(setNonDlModalOpen(false))
                        }
                        orderStatus={(param) => orderStatus(param)}
                      />
                    ) : (
                      <FormTwo
                        downloadable={isDownload}
                        artistid={getSingle?.artist_id}
                        artistname={getSingle?.name}
                        amount={getSingle?.price_withoutDownload}
                        currency={reduxUser?.currency}
                        user={reduxUser}
                        onetime={'true'}
                        closeModalFromChild={() =>
                          closeModalFromChild(setNonDlModalOpen(false))
                        }
                        setOrderType={() => setOrderType(otherRequest(true))}
                        orderStatus={(param) => orderStatus(param)}
                      />
                    )
                  }
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* END OF NON DL MODAL */}
      <Modal
        isOpen={videoModal}
        onRequestClose={() => setVideoModal(false)}
        shouldCloseOnOverlayClick={() => setVideoModal(false)}
        style={videoStyles}
        // overlayClassName="Overlay"
      >
        
        {/* <Stories
          stories={stories}
          defaultInterval={1500}
          width={432}
          height={432}
        /> */}
              <div
          onClick={() => setVideoModal(false)}
          style={{
            position: "absolute",
            right: "0",
            background: "none",
            border: "2px solid white",
            borderRadius: "40px",
            fontSize: "24px",
            height: "40px",
            width: "40px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          X
        </div>
          <div className="video--container">
            <video
            className='video'
            id="video-element"
            src={`https://api.starpokes.com/${welcomeVideo}`}
            style={{ width: "100%", height:"550px",marginTop:"75px"}}
            autoPlay
            playsInline 
            onEnded={() => setVideoButton(play)}
          ></video>
            <div className='controlat' onClick={playVideo}>
          <a href='' id=''>
            <img src={videobutton} alt='' />
          </a>
        </div>
          </div>
      </Modal>

      <Modal
        style={videoStyles}
        isOpen={videoModalOther}
        onRequestClose={() => setVideoModalOpen(false)}
        shouldCloseOnOverlayClick={() => setVideoModalOpen(true)}
        // overlayClassName="overlay"
      >
        <div
          onClick={closeModal}
          style={{
            position: "absolute",
            right: "0",
            background: "none",
            border: "2px solid white",
            borderRadius: "40px",
            fontSize: "24px",
            height: "40px",
            width: "40px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          X
        </div>
        <div className='video--container'>
        <video
          className='video'
          id="video-element"
          src={videoState}
          style={{ width: "100%"}}
          autoPlay
          playsInline 
          onEnded={() => setVideoButton(play)}
        ></video>
        <div className='controlat' onClick={playVideo}>
          <a href='' id=''>
            <img src={videobutton} alt='' />
          </a>
        </div>
        </div>
      </Modal>

      <Modal
        style={videoStyles}
        isOpen={statusModal}
        onRequestClose={() => setStatusModal(false)}
        shouldCloseOnOverlayClick={() => setStatusModal(true)}
        // overlayClassName="overlay"
      >
        <h1>{statusMessage?.message}</h1>
      </Modal>
    </div>
  );
};

export default Artist;
