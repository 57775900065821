import React from "react";
import "./Banner.scss";
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {artists, newcommers, trending, user, picture, login, categories,currency, language, location, currentlocation, translations, currentlanguage} from '../../store/actions/index';

const Banner = (props) => {

  const perkthimet = useSelector((state) => state.translations);

  // const {lang} = perkthimet

  return (
    <>
    <div className='banner'>
      <div className='custom__container'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <h1>
            {perkthimet?.lang?.makeSomeonesDay}
            </h1>
            <h4>{perkthimet?.lang?.personalizedVideoShoutoutsFromTheirFavoritePeople}</h4>
          </div>
        </div>
      </div>
    </div>
    <div className="categories--mobile d-block d-lg-none">
      <div className="inner-cat--mob">
          <Link to="/categories">Categories</Link>
      </div>
    </div>
    </>
  );
};

export default Banner;
