import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"

import {login, user, picture, categories, currency, artists, translations,currentlanguage} from "../../../store/actions/index"

const List = () => {

    const dispatch = useDispatch();

    const perkthimet = useSelector((state) => state.translations);
    const currentLang = useSelector((state) => state.currentlanguage);

    const [selectedLang, setSelectedLang] = useState(currentLang)
    const [langFullName, setFullLangName] = useState('English(US)')

    const handleThis = (code, name) => {

        setSelectedLang(code)
     
        setFullLangName(name)
    
        dispatch(currentlanguage(code))
      }
    return(
        <ul>
        <li><a href="#" onClick={(_) => {handleThis('EN')}}>English</a></li>
        <li><a href="#" onClick={(_) => {handleThis('AL')}}>Shqip</a></li>
        <li><a href="#" onClick={(_) => {handleThis('DE')}}>Deutsch</a></li>
        <li><a href="#" onClick={(_) => {handleThis('TR')}}>Turkish</a></li>
        <li><a href="#" onClick={(_) => {handleThis('IT')}}>Italiano</a></li>
        <li><a href="#" onClick={(_) => {handleThis('RS')}}>Srbski</a></li>
        {/* <li><a href="#">More ...</a></li> */}
        <li>
          <a href="#" className="country--name">More...</a>
        </li>
      </ul>
    )
}

export default List;