import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Banner from '../Banner/Banner';
import { artists, newcommers, trending, user, picture, login, categories, currency, language, location, currentlocation, translations, currentlanguage } from '../../store/actions/index';
import catImage from "../../images/categories/assets/90'music.jpg"

import "./Mobile.scss"

const MobileCategories = () => {

    const cats = useSelector((state) => state.categories);

    const perdoruesi = useSelector((state) => state.user)


    return (
        <>
            <Banner />

            <div className="custom--container mobilecat--container">


                <div className="row no-gutters">

                    {
                        cats !== null && cats?.categories.map((item, index) => {
                           console.log(item, 'foto')
                            return (
                                <div className="col-6">
                                    <Link to={{ pathname: `/subcategories/${item.categoryid}`, state: { cat: item, country: perdoruesi?.homeCountryCode } }}>
                                        <div className="cat--box">
                                            <img src={`https://api.starpokes.com/${item?.picturelocation}`} className="img-fluid" alt="" />
                                            <h5>{item.category_name}</h5>
                                       
                                        </div>
                                    </Link>
                             
                                </div>
                            )
                        })}
                </div>
            </div>
        </>
    )
}

export default MobileCategories;