// const data = []

const newcomersReducer = (state = null, action) => {
    switch(action.type){
        case 'NEWCOMMERS':
            return state = action.payload;
        default: 
            return state;
    }
}

export default newcomersReducer;