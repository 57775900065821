import React, { useState, useEffect, useRef } from "react";
import axios from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { login, location,language, currentlanguage, translations } from "../../../store/actions";
import "./styles.scss";

import useOutsideClick from "../closeDropdown/useOutsideClick"

import ReactTooltip from 'react-tooltip';


import globe from "../../../assets/Globi.png";

import { Select } from "antd";

const Languages = () => {
    const dispatch = useDispatch();
  const componentLocation = useLocation();


  //REfs

  let langMenu = useRef()
  let triggerLangMenu = useRef()

    //Location state from redux

  const location = useSelector((state) => state.language);

  const currentloc = useSelector((state) => state.currentlanguage);

  const perkthimet = useSelector((state) => state.translations)





  // const {lang} = perkthimet;

  const [open, setOpen] = useState('false')

  const {language} = perkthimet || {};


  //States
  const [selectedCountry, setSelectedCountry] = useState("");
//   const [countries, setCountriesToShow] = useState([]);

  //filterat 
  const [countries, setCountries] = useState(location?.data?.langs);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);


  //trigger Dropdown



  const handleThis = (code, name) => {
   
    setSelectedCountry({code:code,name:name})

    dispatch(currentlanguage(code))
  }

  const closeLangMenu = (event) => {
    if(open === 'true'){
      setOpen('false')
    }

        if(!langMenu?.current?.contains(event.target)){
      setOpen('false')
    }
  }

  // document.addEventListener("click", (event) => {

  //   if(!langMenu?.current?.contains(event.target)){
  //     setOpen('false')
  //   }
  // })

 

  useEffect(() => {
    // setIsLoggedIn(getLocalUsers?.login);

    window.addEventListener("click", closeLangMenu);


    setFilteredCountries(
        countries?.filter((country) =>
          country?.language.toLowerCase().includes(search.toLowerCase())
        )
      );

  }, [componentLocation,search]);

  function triggerSearch() {
    if(open === 'false'){

        setOpen('true')
    }else{
        setOpen('false');
    }
}

  return (
    <>
        <div className=""  style={{marginTop:"7px", textAlign:"left", cursor:"pointer"}} ref={langMenu}>
         
        {/* <img
        onClick={triggerSearch}
        src={globe}
        style={{ width: "18px", height: "18px", cursor:"pointer" }}
        alt=''
      />  */}

      {/* //Desktop tooltip */}
      <span className="d-none d-md-block" data-tip="Click to change language" 
       data-background-color="transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%)"
      style={{cursor:"pointer"}}  onClick={triggerSearch} style={{textTransform:"uppercase", verticalAlign:"middle", color:"#cb2a6f", fontWeight:"bold"}}>{language}</span>

  {/* //Mobile Span */}

  <span className="d-block d-md-none" style={{cursor:"pointer"}}   onClick={triggerSearch} style={{textTransform:"uppercase", verticalAlign:"middle", color:"#cb2a6f", fontWeight:"bold"}}>{language}</span>
   
    
     <div className={`countries--dropdown mobile--languages ${open === 'false' ? "d-none" : "d-block"}`} >
        <div className='input--box' >
          <input
            type='text'
            onChange={(e)=> setSearch(e.target.value)}
            //   onFocus={focused}
            //   onBlur={focusedOut}
            //   onChange={filterCountries}
            placeholder='Search languages'
            defaultValue={selectedCountry?.name}
          />
        </div>
        <div className='flags row justify-content-center mt-4' id="scroll">
    
          {filteredCountries && filteredCountries?.map((item, index) => (
         
             
     
   
       
              <div className='col-md-10 countries--show' key={index}>
                <div className='row'>
                  <div className='col'>
                  { item?.prefix === 'EN' ?     <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                  
                      src={`https://cdn.staticaly.com/gh/hampusborgos/country-flags/master/svg/gb.svg`}
                      alt=''
                    /> : 
                    <img
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                
                    src={`https://cdn.staticaly.com/gh/hampusborgos/country-flags/master/svg/${item?.prefix.toLowerCase()}.svg`}
                    alt=''
                  />
                    }
                  </div>
                  <div className='col-10'>
                      
                      <a href="#" className="country--name" onClick={(_) =>{ handleThis(item.code,item.language); setOpen('false')}} >{item.language}</a>
                    </div>
                </div>
              </div>
        
          ))}
        </div>
      </div>
      </div>
    </>
  );
};

export default Languages;
