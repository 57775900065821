import React, {useState, useEffect} from "react";

import axios from "../../../../axios"

import {useSelector} from 'react-redux';

import renderHTML from 'react-render-html'

import {user, location, currentlocation,currentlanguage} from '../../../../store/actions/index';


const Privacy = () => {

    const perkthimet = useSelector(state => state.translations)

    const {lang} = perkthimet

    //States 

    
    const [policy, setPolicy] = useState([])
    
    const {content} = policy
    useEffect(() => {
        
        window.scrollTo({
            top: 50,
            behaviour: "smooth",
          });
        
        axios.get('/api/privacypolicy/getprivacy/mobile/fan')
        .then((res) => {
            setPolicy(res.data);
        })
    }, []);
    return(
        <>
        <h1>{lang?.privacyPolicy}</h1>
        {content && renderHTML(content)}
        </>
    )
}

export default Privacy;