import React, { useState, useEffect, useRef } from "react";
import axios from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { login,user, location, language, currentlocation, translations, currentlanguage } from "../../../store/actions";
import "./styles.scss";

import ReactTooltip from 'react-tooltip';


import globe from "../../../assets/Globi.png";

import { Select } from "antd";

const Languages = () => {
    const dispatch = useDispatch();
  const componentLocation = useLocation();
    //Location state from redux

  const location = useSelector((state) => state.location);

  const currentloc = useSelector((state) => state.currentlocation);

  const perkthimet = useSelector((state) => state.translations);
  const perdoruesi = useSelector((state) => state.user)

  
  let triggerLocMenu = useRef()
  
  // const {lang} = perkthimet;

  const [open, setOpen] = useState('false')


  //States
  const [selectedCountry, setSelectedCountry] = useState("");
//   const [countries, setCountriesToShow] = useState([]);

  //filterat 
  const [countries, setCountries] = useState(location?.data?.locations);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);

  //end of filterat

  //Options from antd select
  const { Option } = Select;


  //onChanges



  //trigger Dropdown

  function triggerSearch() {
      if(open === 'false'){

          setOpen('true')
      }else{
          setOpen('false');
      }
  }

  const handleThis = (code, name) => {
   
    setSelectedCountry({code:code,name:name})

    dispatch(currentlocation(code))
  }

  let locMenu = useRef()
  const closeLocationMenu = (event) => {
        if(!locMenu?.current?.contains(event.target)){
      setOpen('false')
    }
  }



  useEffect(() => {
    // setIsLoggedIn(getLocalUsers?.login);

    // document.addEventListener("click", (event) => {

    //   if(!locMenu?.current?.contains(event.target)){
    //     setOpen('false')
    //   }
    // })

    window.addEventListener("click", closeLocationMenu);

    setFilteredCountries(
        countries?.filter((country) =>
          country.Name.toLowerCase().includes(search.toLowerCase())
        )
      );
  }, [componentLocation,search]);

  let dataTip;

  if(perdoruesi !== null){
    dataTip = "Search stars by country"
  }else{
    dataTip = "Login to search stars by country"
  }

  return (
    <>
        <div className="" style={{marginTop:"5px"}} ref={locMenu}>
            {/* //Desktop icon */}
        <img
        onClick={triggerSearch}
        src={globe}
        style={{ width: "18px", height: "18px", cursor:"pointer" }}
        alt=''
        className="d-none d-md-inline-block"
        data-tip={dataTip}
        data-background-color="transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%)"
      /> 

      {/* //Mobile span */}

      <img
        onClick={triggerSearch}
        src={globe}
        style={{ width: "18px", height: "18px", cursor:"pointer" }}
        alt=''
        className="d-inline-block d-md-none"
        data-tip="Search stars by country"
        data-background-color="transparent linear-gradient(270deg, #3f5cc8 0%, #e12160 100%)"
      /> 

    
      <span onClick={triggerSearch} style={{textTransform:"uppercase", verticalAlign:"middle", color:"#cb2a6f", fontWeight:"bold", marginLeft:"5px"}}>{currentloc}</span>
      <ReactTooltip className="custom--theme" style={{backgroundColor:"red"}} place="bottom" type="error" effect="float"/>
      {/* //Mobile Span */}
       
      {open === 'true' && countries !== undefined && perdoruesi !== null ?  <div className='countries--dropdown' >
        <div className='input--box'>
          <input
            type='text'
            onChange={(e)=> setSearch(e.target.value)}
            //   onFocus={focused}
            //   onBlur={focusedOut}
            //   onChange={filterCountries}
            placeholder='Search Countries Stars'
            defaultValue={selectedCountry?.name}
          />
        </div>
        <div className='flags row justify-content-center mt-4' id="scroll">
{/* 
        <div className='col-md-10 countries--show' >
                <div className='row'>
                  <div className='col'>
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={globe}
                      alt=''
                    />
                  </div>
                  <div className='col-10'>
                      
                  <a href="#" className="country--name" onClick={(_) =>{ handleThis("all","English"); setOpen('false')}} >{perkthimet?.lang?.seeAll}</a>
                    </div>
                </div>
              </div> */}

          {filteredCountries && filteredCountries?.map((item, index) => (
             
            <>
              <div className='col-md-10 countries--show' >
                <div className='row'>
                  <div className='col'>
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={`https://cdn.staticaly.com/gh/hampusborgos/country-flags/master/svg/${item?.Code.toLowerCase()}.svg`}
                      alt=''
                    />
                  </div>
                  <div className='col-10'>
                      
                      <a href="#" className="country--name" onClick={(_) =>{ handleThis(item.Code,item.Name); setOpen('false')}} >{item.Name}</a>
                    </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div> : null }
      </div>
    </>
    //     <Select
    //     showSearch
    //     style={{ width: 400 }}
    //     placeholder="Select a language"
    //     optionFilterProp="children"
    //     onChange={onChange}
    //     onSearch={onSearch}
    //     filterOption={(input, option) =>
    //       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //     }
    //   >

    //   </Select>
  );
};

export default Languages;
