import React, { useState, useEffect, useRef } from "react";
import axios from "../../axios";
import { currentlanguage, translations, user } from "../../store/actions";
import {useSelector} from 'react-redux'




const FormDownloadableSelf = (props,{closeModalFromChild}) => {
  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const useri = JSON.parse(tokeni);

  
  // const {user} = props

  //States

  const [message, setMessage] = useState('')


  const perkthimet = useSelector((state) => state.translations);
  const currentLanguage = useSelector((state) => state.currentlanguage)
  const user = useSelector((state) => state.user)

  //End States  


  console.log(user, 'useri')

  var m = new Date();
  var dateString =
      ("0" + m.getUTCDate()).slice(-2) + "/" +
      ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
      m.getUTCFullYear() + " " +
      ("0" + m.getUTCHours()).slice(-2) + ":" +
      ("0" + m.getUTCMinutes()).slice(-2);

  
      let year = m.getUTCFullYear();

      let userBirthYear = user?.birthday?.split('/')
      

      let userAge = year - Number(userBirthYear[2]);



  const [shoutouttypes, setShoutouttypes] = useState([]);
  const [shoutoutCat, setShoutoutCat] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState('')

  const [errorMsg, setErrorMsg] = useState('');

  const [selectedParentCat, setSelectedParentCat] = useState('');



  const getShoutout = async () => {
    try {
      const shoutouts = await axios.get(`api/shoutout/getparent/${currentLanguage}`,{
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
        }
      });
      setShoutouttypes(shoutouts.data);
      
    } catch (err) {
      console.log(err);
    }
  };
  const setshoutCat = async (e) => {
    try {
     
      // let shoutouts = await axios.get(
      //   `api/shoutout/shoutcategories/${event.target.value}`
      // ,{
      //   headers: {
      //     Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
      //   }
      // });


      let shoutouts = await axios.get(`api/shoutout/getchildbyparent/${e.target.value}/${currentLanguage}`)
      setShoutoutCat(shoutouts.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handle__form__order = async (event) => {
    if(event.target.ordertext.value === '' || event.target.shoutid.value === '' ){
      event.preventDefault();
      setErrorMsg('Please fill up the fields')
    }else{
    setSubmitDisabled('disabled')
    event.preventDefault();
    event.persist();

    let getbankingurl = {
      artistId: props.artistid,
      fanId: user?.fan_id,
      isDownload:1
    }
 


    try{
      axios.post("/api/orders/web/getbanking", getbankingurl).then((response)=> {
  

      let w = 640;
      let h = 740;

      let left = (window.screen.width/2)-(w/2);
      let top = (window.screen.height/2)-(h/2);
      let child = window.open(response.data.url,'_blank','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);



      var timer = setInterval(checkChild, 500);


  

      function checkChild() {
          if (child.closed) {

            
          

            axios.get(`/api/orders/checkorderstatus/${response.data.sid}`)
            .then((res) => {

              if(res.data.message === 'Paid'){
                setMessage('Your order has been successfully sent')
               props.orderStatus({
                paymentstatus:true,
                 message:'Your order has been successfully sent'
               })
                
              }else{
                setMessage('You either canceled or credit card invalid')
                props.orderStatus({
                  paymentstatus:false,
                  message:'You either canceled or credit card invalid'
                })
              }
            })
            
            setSubmitDisabled('')  
              clearInterval(timer);
          }
      }
      props.closeModalFromChild()

   

       try {
        let new_request = {
          artistid: props.artistid,
          fanid: user.fan_id,
          tomail: user.email,
          locationemail:user.homeCountryCode,
          gender:user?.gender,
          isdownload: 1,
          age: userAge,
          ordertext: event.target.ordertext.value,
          ShoutTypeID: event.target.shoutid.value,
          relation: "",
          order_date: dateString,
          videoIsFor: user?.name,
          amount: props.amount,
          currency: props.currency,
          isforsomeone:false,
          paymentURL:response.data.url,
          from:user?.name,
          product:null,
          subproduct:null,
          purchaseDetails:null,
          platform:"Web",
          payment:{
             oid:response.data.oid,
             sid:response.data.sid,
             url:response.data.url,
          },  
        };
         axios
          .post("api/orders/web/addnewrequser", new_request)
          .then((response) => {
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
     })
   }catch(err){
     console.log(err)
   }
  }
   //
  };

  useEffect(() => {
    getShoutout();
  }, []);

  return (
    <>
    <form action='' onSubmit={handle__form__order}>
      <div className='forma'>
        <div className='row'>
          <div className='col-12'>
            <select name='occassion' onChange={setshoutCat}>
              <option style={{ color: "black", display: "none" }}>
                {/* {{perkthimet?.lang?.chooseOccassion}} */}
                {perkthimet?.lang?.chooseOccassion}
              </option>
              {shoutouttypes?.map((el, key) => {
                return (
                  <option style={{ color: "black" }} value={el?.category} key={key} onChange={(e) => setshoutCat(e)}>
                    {el?.category}
                  </option>
                );
              })}
            </select>
          </div>
          {shoutoutCat?.length > 0 ? (
            <div className='col-12'>
              <select name='category' name='shoutid'>
                <option style={{ color: "black", display: "none" }}>
                {perkthimet?.lang?.chooseTheOccassionCategory}
                </option>
                {shoutoutCat?.map((el, key) => {
                  return (
                    <option style={{ color: "black" }} value={el?.shoutid} key={el.shoutid} >
                      {el.shoutdesc}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
        </div>
        <div className='row '>
          <div className='col-12'>
            <textarea type='text' className='fullwidth' name='ordertext' style={{border:"1px solid white"}} />
            <label>{perkthimet?.lang?.instructionsOnWhatYouWantTheCelebToSay}</label>
          </div>
        </div>
        <div className='row justify-content-center'>
         
          <div className='col-md-6 text-center'>
            <input type='submit' value={perkthimet?.lang?.conTinue} className='submitform' disabled={isSubmitDisabled}/>
             
          </div>

          <div className="col-md-12">
            <div className={errorMsg ? 'error--msg' : 'd-none'}>
                {errorMsg}
            </div>
          </div>
        </div>
      </div>
    </form>
  
    </>
  );
};

export default FormDownloadableSelf;
