import React from "react";
import "./style.scss"



const TwoAdds = (props) => {
    return (
     <div className="two--ads">
         <img src={props.image} alt=""/>
     </div>
 
    )
}

export default TwoAdds;