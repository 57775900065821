import React, { useState } from "react";
import "./BecomeVIP.scss";
import axios from "../../axios";
import ClipLoader from "react-spinners/ClipLoader";

const BecomeVIP = () => {
  let [sendingForm, setSendingForm] = useState(false);

  const handleForm = (event) => {
    setSendingForm(true);
    event.preventDefault();
    let data = {
      name: event.target.name.value,
      birthday:
        event.target.day.value +
        "/" +
        event.target.month.value +
        "/" +
        event.target.year.value,
      gender: "M",
      email: event.target.email.value,
      phonenumber: event.target.phone.value,
      location: event.target.location.value,
      reason: event.target.reason.value,
    };

    axios
      .post("/api/fantovip", data)
      .then((res) => {
    
        setSendingForm(false);
      })
      .catch((err) => {
     
        setSendingForm(false);
      });
  };

  return (
    <div className="row no-gutters">
      <div className="col-md-7 leftside__picture">
        <div className="text__content">
          <p>Become a VIP</p>
          <div className="personalized">
            <span>Be an example for people to follow you</span>
          </div>
        </div>
      </div>
      <div className="col-md-5 ">
        <div className="rightside__container">
          <div className="welcome__message">
            <p>Become a VIP</p>
          </div>
          <div className="becomevip__form">
            <form onSubmit={handleForm}>
              <div>
                <input type="text" name="name" />
                <span>Name</span>
              </div>
              <div classname="birthday__gender">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row birthday">
                      <div className="col-12 mt-5"></div>
                      <div className="col-md-3 pr-1">
                        <input type="text" placeholder="Day" name="day" />
                      </div>
                      <div className="col-md-4">
                        <input type="text" placeholder="Month" name="month" />
                      </div>
                      <div className="col-md-5">
                        <input type="text" placeholder="Year" name="year" />
                      </div>
                    </div>
                    <span className="mb-1">Birthday</span>
                  </div>
                  <div className="col-md-5">
                    <div className="row gender--spans">
                      <div className="col-12 mt-5"></div>
                      <div className="col-md-4">
                        <span>M</span>
                        <input type="radio" className="radio__button" />
                      </div>
                      <div className="col-md-4">
                        <span>F</span>
                        <input type="radio" className="radio__button" />
                      </div>
                      <div className="col-md-4">
                        <span>O</span>
                        <input type="radio" className="radio__button" />
                      </div>
                      <span className="mb-1 gender">Gender</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <input type="text" name="email" />
                <span>Email Address</span>
              </div>
              <div className="mt-4">
                <input type="text" name="phone" />
                <span>Phone Number</span>
              </div>
              <div className="mt-4">
                <input type="location" name="location" />
                <span>Location</span>
              </div>
              <div className="mt-4">
                <input type="text" name="reason" />
                <span>Reason you want to become a VIP</span>
              </div>
              <div className="login__button">
                {sendingForm ? (
                  <ClipLoader
                    size={150}
                    color={"#123abc"}
                    loading={sendingForm}
                  />
                ) : (
                  <input type="submit" value="Send" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeVIP;
