import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
// import {login} from "../../old-store/features/userSlice"
import "./style.scss";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {user, picture} from "../../store/actions"

import ClipLoader from "react-spinners/ClipLoader";

import "react-toastify/dist/ReactToastify.css";

import ModalLogo from "../../../images/starpokes.svg";

import { createPortal } from "react-dom";
import { Select } from 'antd';


import {
  Route,
  BrowserRouter as Router,
  Switch,
  Link,
  useHistory,
} from "react-router-dom";

import Login from "../Login/Login"
import List from "../Languages/list"

import {
  login,
  user,
  picture,
  categories,
  currency,
  artists,
  translations,
  language,
  location
} from "../../../store/actions/index";

const modalElement = document.getElementById("modal-root");

export function Signin(
  { children, fade = false, props, defaultOpened = false },
  ref
) {
  let history = useHistory();

  const perkthimet = useSelector((state) => state.translations)
  const langs = useSelector((state) => state.language)
  const locations = useSelector((state) => state.location)


  const { Option } = Select;


  

  const {lang} = perkthimet || {};
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const close = useCallback(() => setIsOpen(false), []);

  let [sendingForm, setSendingForm] = useState(false);
  const [chosenLang, setLanguage] = useState('EN')
  const [chosenLocation, setLocation] = useState('EN')

  const [day, setDay] = useState('dd')
  const [month, setMonth] = useState('MM')
  const [year, setYear] = useState('yyyy')

  
  const [errorMsg, setErrorMsg] = useState("");

  const [dateMsg, setDateMsg] = useState("")

  
  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );
  

  const handleForm = (event) => {
    event.preventDefault();
    if(event.target.name.value.length === 0 
      || event.target.gender.value.length === 0 
      || event.target.email.value.length === 0 
      || event.target.username.value.length === 0
      || event.target.password.value.length === 0
      || event.target.repassword.value.length === 0
      || event.target.language.value.length === 0
      || event.target.location.value.length === 0
      ){
        console.log(event.target.name.value.length, 'name')
        console.log(event.target.gender.value.length,'gender')
        console.log(event.target.email.value.length,'email' )
        console.log(event.target.username.value.length,'userna')
        console.log(event.target.password.value.length,'pw')
        console.log(event.target.repassword.value.length,'repw')
        console.log(event.target.language.value.length,'lang')
        // console.log(event.target.currency.value.length,'curre')
        console.log(event.target.location.value.length,'home')
        setErrorMsg('Please fill all the fields')

    }else if(event.target.day.value > 31 || event.target.day.value < 1 ){
      setDateMsg('Please type a day between 1 and 31')
    }else if(event.target.month.value > 12 || event.target.month.value < 1){
      setDateMsg('Please type a month between 1 and 12')
    }else if(event.target.year.value.length === 0){
      console.log(event.target.year, 'properties')
      setDateMsg('Please type a year')
    }else if(event.target.year.value.length !== 4){
      setDateMsg('Please type a proper value for year')

    }else if(event.target.year.value > 2021 || event.target.year.value < 1930){
      setDateMsg('Please type a year within 1930 and 2021')
    }else if(event.target.password.value !==  event.target.repassword.value){
      setErrorMsg('Passwords do not match')

    }else{
    // setSendingForm(true);
    setDateMsg("")
    setErrorMsg("")
    let data = {
      name: event.target.name?.value,
      birthday:
        event.target.day?.value +
        "/" +
        event.target.month?.value +
        "/" +
        event.target.year?.value,
      gender: "M",
      email: event.target.email?.value,
      username: event.target.username?.value.replace(/\s/g, "").toLowerCase(),
      password: event.target.password?.value,
      locationemail: "US",
      language:chosenLang,
      currency:"EUR",
      homeCountryCode:chosenLocation,
      countryCode: "",
    };



    if (event.target.password.value == event.target.repassword.value) {
      axios
        .post("/api/user/register", data)
        .then((res) => {
       
          setErrorMsg('Congratulations, you registration is complete');
         
        
          setTimeout(function(){ 
            // login.current.open()
            setIsOpen(false)
           }, 3000);
          // setSendingForm(false);
        })
        .catch((err) => {
         
          // setSendingForm(false);
          setErrorMsg('This username Already Exists')
        });
    } else {
      setSendingForm(false);
    }
    }
  

  };


  const getLang = (e) => {
    setLanguage(e.target.value)
  }
  const getLocation = (e) => {
    setLocation(e.target.value)
  }

  
  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  const login = useRef(null)

  return createPortal(
    isOpen ? (
      <div className={`modalis ${fade ? "modalis-fade" : ""}`}>
        <div className='modalis-overlay' onClick={close} />
        <span
          role='button'
          className='modalis-close'
          aria-label='close'
          onClick={close}
        ></span>
        <div className='modalis-body' style={{maxHeight:'100%', height:"100%"}}>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 modal-intro'>
                <img src={ModalLogo} alt='' />

                <p>Please Sign up to continue!</p>
                <span>{errorMsg}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 modal-forma'>
                <form onSubmit={handleForm}>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                        <input type='text' placeholder={lang?.enterName} name="name" />
                      </div>
                    </div>
                    <div className='col-md-10 offset-md-1'>
                      {/* <div className='input--modal--box'>
                        <input type='text' placeholder="Birthday" />
                      </div> */}
                      <div className="row ">
                        <div className="col-md-7">
                            <div className="row no-gutters">
                             <div className="col-md-4">
                              <div className='input--modal--box'>
                              <input type="text" onChange={((e) => setDay(e.target.value))} placeholder="Day" name="day" maxlength="2" />
                                </div>
                             </div>
                             <div className="col-md-4">
                              <div className='input--modal--box'>
                              <input type="text" onChange={((e) => setMonth(e.target.value))} placeholder="Month" name="month" maxlength="2" />
                                </div>
                             </div>
                             <div className="col-md-4">
                              <div className='input--modal--box'>
                              <input type="text" placeholder="Year" setYear="year" name="year" maxlength="4"/>
                                </div>
                             </div>
                             <div className="col-md-12 text-left">
                               <span className="red">{dateMsg}</span>
                               <p className="custom--label">{lang?.yourBirthday}</p>
                             </div>
                            </div>
                        </div>
                        <div className="col-md-4 check-boxat">
                        <div className="row">
                          <div className="col-md-4">
                              <input className='' type='radio' id="m" name='gender' value='M' />
                            <label htmlFor="m">M</label>
                              </div>
                              <div className="col-md-4">
                              <input className='kekbox' type='radio' id="f" name='gender' value='F' />
                            <label htmlFor="f">F</label>
                              </div>
                              <div className="col-md-4">
                              <input className='kekbox' type='radio' id="o" name='gender' value='O' />
                            <label htmlFor="o">O</label>
                              </div>
                              <div className="col-md-4 text-left">
                               <p className="custom--label" style={{paddingTop: "0"}}>{lang?.gender}</p>
                             </div>
                              {/* <div className="col-md-12">
                              <label>Gender</label>

                              </div> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  
                 
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                        <input type='text' name='email' placeholder={lang?.yourEmail} />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                        <input type='text' name='username' placeholder={lang?.enterUsername} />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                              
                              <select className="custom--select" name="language" id="lang" onChange={getLang}>
                              <option value="" disabled selected>{lang?.language}</option>
                                {langs?.data?.langs?.map((item, index) => (
                                <option key={index} value={item.code}>{item.language}</option>
                                ))}
                              </select>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                              
                              <select className="custom--select" name="location" id="lang" onChange={getLocation}>
                              <option value="" disabled selected>{lang?.location}</option>
                                {locations?.data?.locations?.map((item, index) => (
                                <option key={index} value={item.Code}>{item.Name}</option>
                                ))}
                              </select>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                        <input type='password' name='password' placeholder={lang?.enterPassword} />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='input--modal--box'>
                        <input type='password' name='repassword' placeholder={lang?.repeatPassword} />
                      </div>
                    </div>
                    <div className='col-md-10 offset-md-1 check-boxat terms'>
                    <input className='kekbox' type='radio' id="terms" name='terms' required/>
                            <label htmlFor="terms">
                            {/* I have read and accept the <span>Terms and Conditions</span>  and <span>Privacy Policy</span> */}
                              {lang?.byContinuingYouAgreeToTermsAndConditionsAndPrivacyPolicies}
                            </label>
                    </div>
                  </div>

                  <div className='login__button'>
                
                      <input type='submit' value='Sign Up' />
              <p>Already have an Account? <span><a onClick={() => login.current.open()}>Login</a></span></p>
                  </div>
                </form>
                <List />
              </div>
            </div>
          </div>
        </div>
        <Login ref={login}></Login>
      </div>
    ) : null,
    modalElement
  );
}

export default forwardRef(Signin);
