import React, { useState, useEffect, useRef } from "react";
import "./ProfileNav.scss";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import axios from "../../axios";

import { useSelector, useDispatch } from "react-redux"
import {user, picture, login, translations,currentlocation,currentlanguage} from '../../store/actions';



const ProfileNav = (props) => {
  let dispatch = useDispatch()
  let history = useHistory();


  const useri = useSelector (state => state.user)

  const picture = useSelector(state => state.picture)
  const perkthimet = useSelector(state => state.translations)

  // const {lang} = perkthimet


  const logout = (event) => {
    // event.preventDefault();
    setShowProfileNav('false')
    dispatch(login({login:false}))
    dispatch(user(null));
        // dispatch(currentlocation('all'))
    dispatch(currentlanguage('EN'))
    localStorage.clear();
    history.replace("/");
    // props.closeModalFromChild();
    // window.location.href = "/"
  };

  

  // const getSingleArtist = () => {
  //   axios
  //     .get(`api/user/by_username/${useri?.username}`,{
  //       headers: {
  //         Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
  //       }
  //     })
  //     .then((res) => {
  //       setSingleArtist(res.data[0]);
  //       console.log('Nga provileNav',res.data);
  //     })
  //     .catch((error) => console.log(error));
  // };

  const [profileNav, setShowProfileNav] = useState('false')

  const showProfileNav = () => {
    if(profileNav === 'false'){
      setShowProfileNav('true')
    }else{
      setShowProfileNav('false')
    }
  }

  useEffect(() => {
    // getSingleArtist();
  }, [useri]);


  return (
    <div className="wrapper__profile__nav">
      <div className="row no-gutters">
        <div className="col-12 name" onClick={props?.closeModalFromChild}>
          <span onClick={showProfileNav} className="mr-2 d-none d-lg-inline-block"><i className="fa fa-caret-down"></i>{useri?.username.slice(0,8)}</span>
          
          <span  className="mr-2 d-inline-block d-lg-none"><i className="fa fa-caret-down"></i>{useri?.username.slice(0,8)}</span>

          <Link to={`/profile/${useri?.username}`} >
             
           { picture?.image !== null && picture?.image.preview ? (
             <>
                    <img src={picture?.image.preview}/>
                    
                    </>
                    ) : (
                      <>
                    <img src={picture?.image.real} alt=""/>
        
                    </>
                    )}
            </Link>
        </div>
  
        {
          profileNav === 'true' ? <div className="under__profile__nav">
          <div className="row">
            <div className="col-12 profile__nav__links">
              <Link to={{pathname:`/settings`,state:{artisti: useri !== null && useri}}}  data={useri !== null && useri} className="settings" onClick={(e) => setShowProfileNav('false')}>
                Settings
              </Link>
            </div>
            <div className="col-12 profile__nav__links">
              <Link to="/orders" className="settings" onClick={(e) => setShowProfileNav('false')}> 
                {perkthimet?.lang?.requests}
              </Link>
            </div>
            <div className="col-12 profile__nav__links">
              <button className="logout" onClick={logout}>
                {perkthimet?.lang?.logout}
              </button>
            </div>
          </div>
        </div> : ''
        }

          {/* mobile  */}

          <div className="mobile-dropdown-nav">
            <div className="col-12 profile__nav__links ">
                <Link onClick={props?.closeModalFromChild} to={{pathname:`/settings`,state:{artisti: useri !== null && useri}}}  data={useri !== null && useri} className="settings">
                  Settings
                </Link>
              </div>
              <div className="col-12 profile__nav__links">
                <Link to="/orders" className="settings" onClick={props?.closeModalFromChild}> 
                  {perkthimet?.lang?.requests}
                </Link>
              </div>
              <div className="col-12 profile__nav__links">
                <a href="#" className="logout" onClick={logout}>
                  {perkthimet?.lang?.logout}
                </a>
              </div>
          </div>               
      
          {/* end of mobile */}
      </div>
    </div>
  );
};

export default ProfileNav;
