import React, {useState, useEffect} from "react";
import "bootstrap-react";
import { css } from "@emotion/core";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import ScaleLoader from 'react-spinners/ScaleLoader';


import axios from "./axios"

//components
import Homepage from "./Pages/Homepage/HomePage";
import SignUp from "./Pages/SignUp/SignUp";
import Profile from "./Pages/Profile/Profile";
import Login from "./Pages/LogIn/Login";
import BecomeVIP from "./Pages/BecomeVIP/BecomeVIP";
import Navbar from "./Components/Navbar/Navbar";
import "./App.css";
import Artist from "./Components/Artist/Artist";
import LogIn from "./Pages/LogIn/Login";
import "./Pages/LogIn/Login.scss";
import singleCategory from "./Components/SingleCategory/SingleCategory";
import Orders from "./Components/Orders/Orders";
import Settings from "./Pages/Settings/Settings";
import Categories from "./Components/Categories/Categories";
import Footer from "./Components/Footer/Footer";
import PaymentStatus from "./Components/OrdersFinished/PaymentStatus"
import ReactGA from "react-ga"
import Faq from "./Components/Faq/Faq"
import Contact from "./Components/Contact/Contact"




//reduxi
import {useDispatch, useSelector} from 'react-redux';
import {artists,artistvideos,sidebarads,bottomads,middleads,belowads, newcommers, trending, user, picture, login, categories,currency, language, location, currentlocation, translations, currentlanguage} from './store/actions/index';
import MobileCategories from "./Components/Categories/MobileCategories";
import MobileSubcategories from "./Components/Categories/singleMobileCategory/MobileSubcategories";
import SingleSubCategory from "./Components/Categories/singleMobileCategory/SingleSubCategory"


function usePageViews(){
  // const location = useLocation()


  useEffect(() => {
    if(!window.GA_INITIALIZED){
      ReactGA.initialize("UA-194607175-1")
      window.GA_INITIALIZED = true
    }
    ReactGA.set({page: useLocation.pathname});
    ReactGA.pageview(useLocation.pathname)
  }, [useLocation])
}

 const App = () => {
 

  //translate//

  //end of translate//
  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const perdoruesi = JSON.parse(tokeni);

  const dispatch = useDispatch()
  const currentloc = useSelector((state) => state.currentlocation)

  const currentLang = useSelector((state) => state.currentlanguage)
  

  const tempUserStorage = sessionStorage.getItem('user');





  

  if(perdoruesi === null || perdoruesi === undefined){
    if(tempUserStorage === null){

      axios.get("https://ipinfo.io?token=1e4133f02dbfe8").then(res => {
        dispatch(currentlocation(res.data.country))
      })
      .catch(err => {
        dispatch(currentlocation('US'))
      })
  
    }else{
      dispatch(currentlocation(tempUserStorage))
    }

  }else{
    if(tempUserStorage === null){
      sessionStorage.setItem('user', currentloc)
    }else{
    }
  }

  
  
  // console.log(tempUserStorage, 'tempuserS')

  



  axios.post(`api/artist/newcommers/${currentLang}`, {"countryCode" : currentloc})
  .then(res => dispatch(newcommers(res.data)))

  if(perdoruesi === null || perdoruesi === undefined ){
    

    axios.get(`/api/languages/getlanguages/${currentLang}`).then(
      (res) => {

      
        dispatch(translations({
          lang: res.data.words,
          selected:res?.data?.code,
          language:res.data.language
        }))
      }
    )

  }else{
    axios.get(`/api/languages/getlanguages/${currentLang}`).then(
      (res) => {
        dispatch(translations({
          lang: res.data.words,
          selected:res?.data?.code,
          language:res.data.language
          
        }))
      }
    )
  }

  axios
  .post(`api/all/trendingartists/${currentloc}`, {location: currentloc})
  .then(res => dispatch(trending(res.data)));

  dispatch(currency({
    currency:'USD'
   }))

   axios
   .get(`/api/locations`)
   .then((res) => {
    //  setLocation(res.data);
    

    dispatch(location({
      data:{
        locations:res.data,
        selected:currentloc
      }
      
    }))



   })
   .catch((error) => console.log(error));

   axios
   .get(`/api/languages/getalllang`)
   .then((res) => {
    //  setLocation(res.data);
    dispatch(language({
      data:{
        langs:res.data,
        selected:"null"
      }
      
    }))
  
   })
   .catch((error) => console.log(error));



  if(perdoruesi === null){

    
  
    axios.get(`api/categories/artistsbyparent/${currentloc}`).then(res => dispatch(artists(res.data)))
    axios
    .get(`api/categories/filteredcat/${currentloc}`)
    .then((res) => {
      // getCategoriesall(res.data);
     
      dispatch(categories({
        categories: res.data,
      }))
    })
    
  }else{
    axios.get(`api/categories/artistsbyparent/${currentloc}`).then(res => dispatch(artists(res.data)))
    axios
    .get(`api/categories/filteredcat/${currentloc}`)
    .then((res) => {
      // getCategoriesall(res.data);
      // dispatch(currentlocation(perdoruesi?.useri?.user?.homeCountryCode))
      dispatch(categories({
        categories: res.data,
      }))
    })  
    
  }

  if(perdoruesi !== null){
    axios.get(`api/all/fansat/get/${perdoruesi?.useri?.id}`,{
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
      }
    }).then(res =>{
      dispatch(user(res.data))
  
      dispatch(login({
        login: true,
      }))

      dispatch(picture({
        image:{
          real:`https://api.starpokes.com/${res.data.picturelocation}`,
          preview:null
        }
        
      }))
      dispatch(currency({
        currency:res?.data.currency
       }))
    } )
  }


 //reklamat

let object = {
  countryCode:currentloc,
  locationId:5
}
axios.post(`api/advertisement/artistvideos`, object)
.then(res => {
  dispatch(artistvideos(res.data))
})

axios.post(`/api/advertisement/getadds`,{
  countryCode:currentloc,
  locationId:1
})
.then(res => {
  dispatch(sidebarads(res.data))

})
.catch(err => {
  console.log(err)
})

axios.post(`/api/advertisement/getadds`,{
  countryCode:currentloc,
  locationId:2
})
.then(res => {
  dispatch(bottomads(res.data))

})
.catch(err => {
  console.log(err)
})

axios.post(`/api/advertisement/getadds`,{
  countryCode:currentloc,
  locationId:3
})
.then(res => {
  console.log(res.data, '3')
  dispatch(middleads(res.data))

})
.catch(err => {
  console.log(err)
})


axios.post(`/api/advertisement/getadds`,{
  countryCode:currentloc,
  locationId:4
})
.then(res => {
  console.log(res.data, '4')
  dispatch(belowads(res.data))

})
.catch(err => {
  console.log(err)
})



//end of reklamant


//   let perkthimet = useSelector(state => state.translations)
  

// const {lang} = perkthimet

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

`;




  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#cb2a6f");

  //set Sticky Navbar

  // usePageViews();


  ReactGA.pageview(window.location.pathname + window.location.search)
  
  useEffect(()=>{
    setLoading(true)


    ReactGA.initialize('UA-194607175-1')

    //to report page view

    // ReactGA.pageview('/')

    ReactGA.pageview(window.location.pathname + window.location.search)
   
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  },[])

  return (
    // <SmoothProvider>
    <Router>
      { loading ? 
     <>
      <ScaleLoader color={color} css={override} loading={loading} height='90' width='10' size={150} />
      {/* <h1>Zbukuroja Diten dikujt</h1> */}
     </>
 : <>
 <div className='main--wrapper'>
        <Navbar />
        {/* <div className='nav'>
        <div className="custom__container">
          <div className='row'>
          <Navbar></Navbar>
           
            </div>
            <Categories></Categories>
          </div>
       
        </div> */}
      </div>
      <Switch>
        <Route path='/' exact component={Homepage}></Route>
        <Route path='/artist/:artist'  component={Artist}></Route>
        <Route path='/signin' component={LogIn}></Route>
        <Route path='/signup' component={SignUp}></Route>
        <Route path='/becomevip' component={BecomeVIP}></Route>
        <Route path='/profile' component={Profile}></Route>
        <Route path='/singleCategory' component={singleCategory}></Route>
        <Route path='/orders' component={Orders}></Route>
        <Route path='/settings' component={Settings}></Route>
        <Route path='/orderfinished' component={PaymentStatus}></Route>
        <Route path='/categories' component={MobileCategories}></Route>
        <Route path='/subcategories' component={MobileSubcategories}></Route>
        <Route path='/singlesubcategory' component={SingleSubCategory}></Route>
        <Route path='/subcategory'></Route>
        <Route path='/faq' component={Faq}></Route>
        <Route path='/contact' component={Contact}></Route>

      </Switch>
      <Footer />
 </>}
    </Router>
    // </SmoothProvider>
  );
}

export default App;
