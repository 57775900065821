import React, { useState, useEffect } from "react";
import axios from "../../../../axios";
import "./password.scss";

import 'react-toastify/dist/ReactToastify.css';

import "../EditProfile/style.scss"
import {useDispatch, useSelector} from 'react-redux';



import {user, location, currentlocation} from '../../../../store/actions/index';

const queryString = require('query-string');

const Password = (props) => {

  const status = queryString.parse(props.location.search).reset;

  const tokeni = window.localStorage.getItem("user", JSON.stringify());
  const user = JSON.parse(tokeni);
 
  const [errorMsg, setErrorMsg] = useState()
  const [forgotPassword, setForgottenPassword] = useState('false')

  const perkthimet = useSelector(state => state.translations);

  const reduxUser = useSelector((state) => state.user)


  const {lang} = perkthimet
 
const handleChange = (e) => {

}

const updateTempPassword = async (event) => {
  event.preventDefault();

  let config = {
    id: user?.useri?.fan_id,
    password: event.target.new_password.value,
    // password: event.target.re_new_password.value,
    type:"F",
    headers: {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
    },
  };

  
  if(event.target.new_password.value === '' || event.target.re_new_password.value === ''){
    setErrorMsg('Please fill the fields')
  }else{
    try {
      await axios.put("api/all/resetpassword", config).then((response) => {
        
        setErrorMsg(response.data.msg);
      });
    } catch (err) {
      console.log(err);
    }
  }
}

  const updatePassword = async (event) => {
    event.preventDefault();
   
    let config = {
      id: user?.useri?.id,
      old_password: event.target.old_password.value,
      new_password: event.target.new_password.value,
      re_new_password: event.target.re_new_password.value,
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
      },
    };

    if(event.target.old_password.value === '' || event.target.new_password.value === '' || event.target.re_new_password.value === ''){
      setErrorMsg('Please fill the fields')
    }else{
      try {
        await axios.put("api/user/updatepassword", config).then((response) => {
          
          setErrorMsg(response.data.msg);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if(status === 'true'){
    
      setForgottenPassword('true')
    }
  }, []);

  return (
    <div className='container'>
   
      <div className='row'>
        <div className='col-md-12'>
          <h1>{lang?.changePassword}</h1>
        </div>
      </div>
      <div className='row'>
        <div className="col-md-12">
        {errorMsg}
        </div>

        {
          forgotPassword  === 'false' ? 
          <div className='col-md-6'>
          <form action='' className='update-password' onSubmit={updatePassword}>
            <div className='row'>
              <div className='col-md-12'>
                <input type='password' name='old_password' onChange={handleChange} />
                <label htmlFor=''> {lang?.oldPassword}</label>
              </div>
              <div className='col-md-12'>
                <input type='password' name='new_password' onChange={handleChange} />
                <label htmlFor=''> {lang?.newPassword}</label>
              </div>
              <div className='col-md-12'>
                <input type='password' name='re_new_password' onChange={handleChange} />
                <label htmlFor=''> {lang?.repeatNewPassword}</label>
              </div>
              <div className='col-md-12'>
                <button>Update</button>
              
              </div>
              
            </div>
          </form>
        </div> : 
        <div className="col-md-6">

        <form action='' className='update-password' onSubmit={updateTempPassword}>
          <div className="row">
               <div className='col-md-12'>
                <input type='password' name='new_password' onChange={handleChange} />
                <label htmlFor=''> {lang?.newPassword}</label>
              </div>
              <div className='col-md-12'>
                <input type='password' name='re_new_password' onChange={handleChange} />
                <label htmlFor=''> {lang?.repeatNewPassword}</label>
              </div>
              <div className='col-md-12'>
                <button>Update</button>
              
              </div>
          </div>
        </form>
        </div>
        }

      </div>
    </div>
  );
};

export default Password;
