import React,{useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { LazyLoadImage,LazyLoadComponent  } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import axios from "../../../axios"

const Vip = (props) => {

 
    return(
   
        <div className={`vip--card col`} key={props._id} item={props.item}>
            <div className="inner--vip">
            {/* <Link to={ user ? `/artist/${item.username}` : "#"}> */}
            <Link to={{pathname:`/artist/${props.username}`, state:{artisti:props.item}}}  link={props.link}>
                <LazyLoadImage
                effect="blur" 
                src={`https://api.starpokes.com/${props.picturelocation}`}
                />
               
         
                <div className="vip--inner">
                <p>
                    {props.name}
                </p>
                <div className="vip--info">
                
                   
                    <span>{props.CategoryID}</span>
                  
                </div>
                </div>
                <div className="card--border">
                
                </div>
            </Link>
            </div>

        </div>
    
    )
}

export default Vip