import React, { useState, useEffect } from "react";
import axios from "../../../../axios";

import {useDispatch, useSelector} from 'react-redux';

import {user, location, currentlocation} from '../../../../store/actions/index';


import { Select } from "antd";


const Location = () => {

  let dispatch = useDispatch()

  const [getLocation, setLocation] = useState();
  const [current, setCurrent] = useState(false);


  const locationi = useSelector((state) => state.location);
  const currentloc = useSelector((state) => state.currentlocation)
  const perkthimet = useSelector(state => state.translations)
  const user = useSelector((state) => state.user)

  const [updated, setIsUpdated] = useState(false)
  const [msg, setMsg] = useState('')
  const [locationChanged, setNewLocation] = useState(user?.language)



  const {lang} = perkthimet

  const { Option } = Select;

  const getUserLocation = () => {
    axios
      .get(`/api/locations`)
      .then((res) => {
        setLocation(res.data);

        // dispatch(location({
        //   data:{
        //     locations:res.data,
        //     selected:location?.data?.selected
        //   }
          
        // }))
      })
      .catch((error) => console.log(error));
  };


  const submitForm = (event) => {
    event.preventDefault()

    console.log(locationChanged)

    let config = {
        homeCountryCode: current,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
        },
      };

    // if (!user.name || !user.username) return

    // props.addUser(user)
    // setUser(useri)
    // dispatch(currentlocation(current))

    axios.put(`api/user/fansat/${user?._id}`, config).then(res => {

        setIsUpdated(true);
    })
 
    setMsg('Profile updated successfully')
    setTimeout(function(){ 
        setMsg('')
     }, 6000);

  }

  
  function onChange(value) {
    setCurrent(value);
    setNewLocation(value)
  }
  function onSearch(val) {
    setCurrent(val);
  }

  function changeLocation(){

    // dispatch(currentlocation(current))
  
  
  }

  useEffect(() => {
    getUserLocation();
  }, []);

  return (
    <div className="container edit-profile">
      <div className="row">
        <div className="col-md-12">
          <h1>{lang?.location}</h1>
          <p>{msg}</p>
        </div>
      </div>
          <form action="" onSubmit={submitForm}>
      <div className="row">
        <div className="col-md-12">

          <div className="use-gradient" style={{display:"inline-block"}}>

              <Select
                // showSearch
                style={{ width: 300 }}
                placeholder={lang?.enterLocation}
                optionFilterProp='children'
                defaultValue={user?.homeCountryCode}
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {locationi !== null &&
                  Object.keys(locationi?.data?.locations).map(function (
                    key,
                    index
                  ) {
                 
                    return (
                      <Option
                        key={index}
                        value={locationi?.data?.locations[index]?.Code}
                      >
                        {" "}
                        {locationi?.data?.locations[index]?.Name}{" "}
                      </Option>
                    );
                    
                    
                  })}
              </Select>
            </div>
        </div>
        <div className="col-md-12">
        <button onClick={changeLocation} type="submit" style={{marginTop: "20px"}}>Update Location</button>

        </div>
      </div>
          </form>
    </div>
  );
};

export default Location;
