import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';



const HomeSingleAdd = (props) => {

    return (
        <div className="single--image">
          {/* <img  alt=""/> */}
          <LazyLoadImage src={props.image}  style={{maxWidth:"97%",width:"100%", paddingLeft:"15px"}}/>
      </div>
    )
}

export default HomeSingleAdd;
