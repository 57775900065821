// const data = []

const currentlocationReducer = (state = null, action) => {
    switch(action.type){
        case 'CURRENTLOCATION':
            return state = action.payload
        default: 
            return state;
    }
}

export default currentlocationReducer;