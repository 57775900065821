import React, {useRef} from "react";
import { Link } from "react-router-dom";
import Login from "../PopUps/Login/Login"
import Singin from "../PopUps/SignIn/Signin"
const SignUp = (props) => {

  const login = useRef(null)
  const signin = useRef(null)

  let {lang} = props
  

  return (
    <>
      <div className="profile--buttons">
      <div className="box"> 
      {/* <Link className="login--button" to="/signin">Login
      </Link> */}
      
      <a onClick={() => login.current.open()}>{lang?.login}</a>
      </div>
     
    
      <div className="box box-1">
        {/* <Link to="/signup" className="sign--up--button">
          Sign Up
        </Link> */}
        <a onClick={() => signin.current.open()} className="sign--up--button">Sign Up</a>
      </div>

   

     
        </div>
        <Login ref={login}>
            
        </Login>
        <Singin ref={signin}></Singin>

   </>
  );
};

export default SignUp;