import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Add1 from "../../images/baki.png"
import Add2 from "../../images/add2.jpg"
import Add3 from "../../images/add3.jpg"
import Add4 from "../../images/add4.jpg"

import "./style.scss"

import {useSelector} from "react-redux"

import { sidebarads } from "../../store/actions/index";




const Homeadds = () => {

    const reklamat = useSelector(state => state.sidebarads);

    console.log(reklamat, 'reklo')
    return(
       <div className="row adds">

           {
               reklamat?.map((reklama, index) => {
                   return(
                    <div className="col-md-12">
                        <a href={reklama?.url} target="_blank" rel="noopener noreferrer">
                            <LazyLoadImage alt={Add1} src={`https://api.starpokes.com/` + reklama?.path_url}/>
                        </a>
                </div>
                   )
               })
           }
       
           {/* <div className="col-md-12">.
               <a href="https://cacttus.com/harrisia" target="_blank" rel="noopener noreferrer">
                    <LazyLoadImage alt={Add2} src={Add2}/>
               </a>
           </div>
           <div className="col-md-12">
                <a href="https://cacttus.com/cbs" target="_blank" rel="noopener noreferrer">
                    <LazyLoadImage alt={Add3} src={Add3}/>
                </a>
           </div>
           <div className="col-md-12">
                <a href="https://cacttus.education/" target="_blank" rel="noopener noreferrer">
                <LazyLoadImage alt={Add4} src={Add4}/>
                </a>
           </div> */}
       </div>
      
    )
}

export default Homeadds