import React, { useEffect, useImperativeHandle, useState, forwardRef, useCallback,useRef } from 'react'
import {useDispatch, useSelector} from "react-redux"
// import {login} from "../../old-store/features/userSlice"
import "./style.scss";
import axios from "../../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {user, picture} from "../../store/actions"

import ModalLogo from "../../../images/starpokes.svg";

import {createPortal} from 'react-dom'

import Login from "../Login/Login"
import List from "../Languages/list"

import {
    Route,
    BrowserRouter as Router,
    Switch,
    Link,
    useHistory,
  } from "react-router-dom";

import {login, user, picture, categories, currency, artists, translations} from "../../../store/actions/index"


const modalElement = document.getElementById('modal-root')

export function Forgot({children, fade = false, props, defaultOpened = false}, ref) {

    let history = useHistory();

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(defaultOpened)
    const [forgotMessage, setForgotMessage] = useState('')

    const close = useCallback(() => setIsOpen(false), [])

    const perkthimet = useSelector((state) => state.translations)


    const {lang} = perkthimet


 
    const [formControl, setFormControl] = useState();
  
    
    const handleReset = (e) => {
        e.preventDefault();

        axios.post(`api/forgotpassword/`, {email:e.target.forgotmail.value})
        .then((res) => 
        {
          setForgotMessage(res.data.msg)
          
        }
        
        )
        .catch((err) => 
        {
          setForgotMessage('Something went wrong, please try again later')}
        )
    }
 

 const switchView = () => {
      setIsOpen(false);
      login.current.open();
    };



    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      closeForgot:() => setIsOpen(false),
      close
    }), [close])
  
    const handleEscape = useCallback(event => {
      if (event.keyCode === 27) close()
    }, [close])

    useEffect(() => {
        if (isOpen) document.addEventListener('keydown', handleEscape, false)
        return () => {
          document.removeEventListener('keydown', handleEscape, false)
        }
      }, [handleEscape, isOpen])


      const login = useRef(null)
    
      const forgotLogin = useRef(null)
    return(
       createPortal(
         isOpen ?
         
         <div className={`modalis ${fade ? 'modalis-fade' : ''}`}>
         <div className="modalis-overlay" onClick={close} />
         <span role="button" className="modalis-close" aria-label="close" onClick={close}>
      
         </span>
         <div className="modalis-body" style={{minHeight:"78%", height:"78%"}}>
             <div className="container">
                 <div className="row">
                     <div className="col-md-12 modal-intro" style={{marginTop:"100px"}}>
                         <img src={ModalLogo} alt=""/>

                         {/* <p>{lang?.pleaseSignInToContinue}!</p> */}
                         <p>Enter Email Address to change password</p>
                         
                         <span>{forgotMessage}</span>
                     </div>
                 </div>
                 <div className="row">
                     <div className="col-md-12 modal-forma" style={{marginTop:"30px"}}>
                     <form onSubmit={handleReset}>
                         <div className="input--modal--box">

                        <input
                                type='text'
                                name='forgotmail'
                            
                                // className="form-control "
                                id='exampleInputEmail1'
                            
                                aria-describedby='emailHelp'
                                placeholder={lang?.yourEmail}
                                required
                            />
                         </div>                                       
                            <div className='login__button' style={{marginTop:"50px"}}>
                                <input type='submit' value='Reset Password'/>

                                 <p>{lang?.dontHaveAnAccount} <a onClick={switchView} href="#">Sign UP</a></p>
                            </div>
                        </form>
                        <List />
                     </div>
                 </div>
             </div>
 
         </div>
         <Login ref={login}></Login>
       </div>
       
 : null ,modalElement
       )
    )
}

export default forwardRef(Forgot)