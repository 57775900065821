import React,{useState, useEffect} from 'react'
import "./style.scss"
import axios from "../../axios"
import  {user,  currentlocation, translations, currentlanguage} from '../../store/actions/index';
import {useSelector} from "react-redux"

// './store/actions/index';


export default function Faq() {
    const [fanFaq, setFanFaq] = useState([])
    const [vipFaq, setVipFaq] = useState([])
    const Accordion = ({ title, children }) => {
        const [isOpen, setOpen] = useState(false);
      

        const user = useSelector((state) => state.user)
        const currentlanguage = useSelector((state) => state.currentlanguage)

        
        
        return (
          <div className="accordion-wrapper">
            
            <div
              className={`accordion-title ${isOpen ? "open" : ""}`}
              onClick={() => setOpen(!isOpen)}
              >
              {title}
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
              <div className="accordion-content">{children}</div>
            </div>
          </div>
        );
      };

      useEffect(() => {
        axios.get(`/api/faq/getfaq/dashboard/F/${currentlanguage}`)
        .then(
            (res) => 
            {
                setFanFaq(res.data)

                console.log(res.data,'data faq')
                
            }
        ).catch((err) => console.log(err))

        axios.get(`/api/faq/getfaq/dashboard/V/${currentlanguage}`)
        .then(
            (res) => 
            {
                setVipFaq(res.data)
            }
        ).catch((err) => console.log(err))
        
        window.scrollTo({
          top: 50,
          behaviour: "smooth",
        });


      },[])



    return (
        <div className=" custom__container faq">
        
        <div className="row">
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-12">
                        <h1>FAN FAQs</h1>
                    </div>
                    <div className="col-md-12 wrapper">
                        {/* {
                                       fanFaq?.faq_question?.map((item,index) => {
                                           console.log(item)
                                        return(
                                        )
                                      })
                        } */}


                      {fanFaq?.map((el, index) => {
                        console.log(el, index)
                        return(
                          <Accordion title={el?.text} key={el?.faq_id}>
                            {el?.child_faqs.map((child) => {
                              return(
                                  child?.text
                              )
                            })
                          }
                          </Accordion>
                        )
                      })}
                      
                    </div>
                </div>
            </div>
            <div className="col-md-6">
            <div className="row">
                    <div className="col-md-12">
                        <h1>STAR FAQs</h1>
                    </div>
                    <div className="col-md-12 wrapper">
                        {/* {
                                       fanFaq?.faq_question?.map((item,index) => {
                                           console.log(item)
                                        return(
                                        )
                                      })
                        } */}


                      {vipFaq?.map((el, index) => {
                        console.log(el, index)
                        return(
                          <Accordion title={el?.text} key={el?.faq_id}>
                            {el?.child_faqs.map((child) => {
                              return(
                                  child?.text
                              )
                            })
                          }
                          </Accordion>
                        )
                      })}
                      
                    </div>

                </div>
            </div>
        </div>
    </div>
    )
}
