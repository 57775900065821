import React, {useState, useEffect, useRef} from "react";
import {
  Router,
  Route,
  Link,
  browserHistory,
  IndexRoute,
  useHistory
} from "react-router-dom";
import axios from "../../axios"


import Location from "./Components/Location/Location";
import Terms from "./Components/Terms/Terms";
import PrivacyPolicy from "./Components/PrivacyPolicy/Privacy"
import Password from "./Components/Password/Password";
import Language from "./Components/Language/Language";
import EditProfile from "./Components/EditProfile/EditProfile"
import Adds from '../../Components/Adds/Adds'

import "./settings.scss";
import Documents from "../../assets/documents.png"
import Pin from "../../assets/pin.png";
import Lock from "../../assets/lock.png";
import Translate from "../../assets/translate.png";
import Term from "../../assets/documents.png";
import Logout from "../../assets/logout.png";
import Next from "../../assets/next.png";
import Add from "../../images/baki.png"
import SettingsLogo from "../../assets/settings.svg"


import {useSelector, useDispatch} from "react-redux"
import {user, picture,translations,login} from '../../store/actions';

const Settings = (props) => {

  const history = useHistory()

  const dispatch = useDispatch()





 

  const useri = useSelector (state => state.user)
  const picture = useSelector (state => state.picture)
  const perkthimet = useSelector(state => state.translations)
  const {lang} = perkthimet

  


  // const User = props?.location.state.artisti;

  const [userprofile, setUserProfile] = useState(useri !== null && useri)


  

  // const getPrifleInfo = (id) => {
  //   axios.get(`api/all/fansat/get/${useri?.user?.user?._id}`).then(res => setUserProfile(res.data))
  // }

  const logout = (e) => {

    e.preventDefault();
    dispatch(login({login:false}))
    dispatch(user(null));
    localStorage.clear();
    history.replace("/");
    // window.location.href = "/"
  };



const [profileMenu, setProfileMenuOpen] = useState(false)

const toggleProfileMenu = () => {
    if(profileMenu === false){
        setProfileMenuOpen(true)
        // closeLocationMenu()
    }else{
        setProfileMenuOpen(false)
        // closeLocationMenu()
    }
}


let sidebar = useRef()

const closeLocationMenu = (event) => {
      if(!sidebar?.current?.contains(event.target)){
        setProfileMenuOpen(false)
  }
  // console.log(event.target, 'event')
}
useEffect(() => {

  // getPrifleInfo()

  window.addEventListener("click", closeLocationMenu);

}, []);

  return (
    <div className="settings--wrapper">
      <div className="container-fluid">
        <div className="row">
            {
              useri ?       <div className='sidebar' ref={sidebar}  style={profileMenu ? {left:0} : {left:'-425px'}}>
              { picture?.image !== null && picture?.image.preview ? (
                            <>
                        <img src={picture?.image.preview} className="d-none d-md-block" />
                        
                        </>
                        ) : (
                        <>
                        <img src={picture?.image.real} alt="" className="d-none d-md-block"/>
            
                        </>
                        )}
                
              <h6>{useri?.user?.user?.name}</h6>
                <div className="settings-menu">
                  <ul>
                    <li>
                    
                    <img className="ikona" src={Documents} alt="" />
                    <Link to={{pathname:`/settings`,state:{artisti: useri !== null && useri}}} onClick={(e) => setProfileMenuOpen(false)}>Edit Profile</Link>
                    </li>
                    <li>
                      <img className="ikona" src={Pin} alt="" />
                      <Link to="/settings/location" onClick={(e) => setProfileMenuOpen(false)}>{lang?.chooseHomeCountry}</Link>
                      
                    </li>
                    <li>
                      <img className="ikona" src={Lock} alt="" />
                      <Link to="/settings/password" onClick={(e) => setProfileMenuOpen(false)}>{lang?.changePassword} </Link>
    
                      
                    </li>
                    <li>
                      <img className="ikona" src={Term} alt="" />
                      <Link to="/settings/terms" onClick={(e) => setProfileMenuOpen(false)}>{lang?.termsAndConditions} </Link>
                      
                    </li>
                    <li>
                      <img className="ikona" src={Term} alt="" />
                      <Link to="/settings/privacy" onClick={(e) => setProfileMenuOpen(false)}>{lang?.privacyPolicy} </Link>
                      
                    </li>
                    <li>
                      <img className="ikona" src={Translate} alt="" />
    
                      <Link to="/settings/language" onClick={(e) => setProfileMenuOpen(false)}>{lang?.language} </Link>
    
                      
                    </li>
                    <li>
                      <img className="ikona" src={Logout} alt="" />
                      <a href="" onClick={logout} >{lang?.logout}</a>
                      
                    </li>
                  </ul>
                </div>
              </div> : null
            }
          <div className={useri ? "col-md-6" : "col-md-9"}>
             {/* { useri ?  '' : ''} */}

             <div className={`settings--content`}>
                <div className={`row ${useri ? 'd-flex' : 'd-none'}`}>
                    <div className="col-6 ">
                        {/* <h1>Edit Profile</h1> */}
                    </div>
                    <div className="col-6 text-right d-block d-md-none mobile--profile--menu" ref={sidebar}>
                        <img src={SettingsLogo} onClick={toggleProfileMenu} className="img-fluid" alt="" style={{height:"35px", width:"35px",marginTop:"15px"}}/>
                    </div>
                </div>

              {/* <EditProfile /> */}
              <Route path="/settings" exact={true} component={EditProfile} ></Route>
              <Route path="/settings/location" component={Location}></Route>
              <Route path="/settings/password" component={Password}></Route>
              <Route path="/settings/terms" component={Terms}></Route>
              <Route path="/settings/privacy" component={PrivacyPolicy}></Route>
              <Route path="/settings/language" component={Language}></Route>
            </div>
          </div>
          <div className="col-md-3 text-right d-none d-lg-block">
          <Adds image={Add} />
          </div>
        </div>
      </div>
    </div>

    /* <div className="settings--wrapper">
  <div className="settings-menu">
      <ul>
        <li>
          <img className="ikona" src={Pin} alt="" />
          <Link to="/settings/location">Location</Link>
        </li>
        <li>
          <img className="ikona" src={Lock} alt="" />
          <Link to="/settings/password">Password Settings </Link>

        </li>
        <li>
          <img className="ikona" src={Term} alt="" />
          <Link to="/settings/terms">Terms & Conditions </Link>
        </li>
        <li>
          <img className="ikona" src={Translate} alt="" />

          <Link to="/settings/language">Language </Link>

        </li>
        <li>
          <img className="ikona" src={Logout} alt="" />
          <a href="">Logout</a>
        </li>
      </ul>
    </div>
  </div> */


  );
};

export default Settings;
