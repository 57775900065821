import React from "react";
import "./style.scss"
import { LazyLoadImage } from 'react-lazy-load-image-component';



const Adds = (props) => {
    return (
        <div className="image">
          {/* <img  alt=""/> */}
          <LazyLoadImage src={props.image} />
      </div>
    )
}

export default Adds;