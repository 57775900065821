import React, {useState, useEffect} from 'react'
import imageCompression from 'browser-image-compression';

import "./style.scss"
import axios from "../../../../axios"


import { useSelector, useDispatch } from "react-redux"
import {user, picture,currency, translations} from '../../../../store/actions/index';

import Lapsi from "../../../../assets/lapsi.png"
import SettingsLogo from "../../../../assets/settings.svg"

import { Select } from 'antd';

const { Option } = Select;

const EditProfile = (props) => {
    const dispatch = useDispatch()
    
    const User = props?.location?.state?.artisti
    
    
    const reduxUser = useSelector (state => state.user)
    const picturelocation = useSelector (state => state.picture)
    const perkthimet = useSelector((state) => state.translations)



    const stateCurrency = useSelector(state=> state.currency )


    const {lang} = perkthimet

    //States 

    const [msg, setMsg] = useState('')
    
    

      function onSearch(val) {

        // dispatch(currency({
        //    currency:val
        //   }))
      }

    const [image, setImage] = useState({ preview: "", raw: "" });
    const [currenci, setCurrency] = useState({})
    const [updated, setIsUpdated] = useState(false)

    const [trueImage, setTrueImage] = useState(`https://api.starpokes.com/${picturelocation?.image}`)

    const [useri, setUser] = useState(User)

    const [userCurrency, setUserCurrency] = useState('')

    const [error, setError] = useState()
    const [isValid, setIsValid] = useState()
    const [birthday , setBirthday] = useState()


    const  getCurrency = (value) => {

        setUserCurrency(value);
        setUser({...useri, "currency":value} )

     
      }

      
    //   const validateBirthday = (day,month,year) => {
    //     if(useri.birthday.split("/")[0] > Number(31) || useri.birthday.split("/")[0] < Number(1)){
    //         setError('Please type a day between 1 and 31')
    //         setIsValid(false)
    //     }else if(useri.birthday.split("/")[1] > 12 || useri.birthday.split("/")[1] < 1){
    //         setError('Please type a month between 1 and 12')
    //         setIsValid(false)
    //     }else if(useri.birthday.split("/")[2].length !== 4){
    //         setError('Please type a valid year')
    //         setIsValid(false)
    //     }else{
    //       setIsValid(true)
    //     }
    // }

    // validateBirthday(useri.birthday.split("/")[0],useri.birthday.split("/")[1],useri.birthday.split("/")[2])

    // if(useri.birthday.split("/")[0] > Number(31) || useri.birthday.split("/")[0] < Number(1)){
    //     setError('Please type a day between 1 and 31')
    //     setIsValid(false)
    //     }else if(useri.birthday.split("/")[1] > 12 || useri.birthday.split("/")[1] < 1){
    //         setError('Please type a month between 1 and 12')
    //         setIsValid(false)
    //     }else if(useri.birthday.split("/")[2].length !== 4){
    //         setError('Please type a valid year')
    //         setIsValid(false)
    //     }else{
    //     setIsValid(true)
    // }


    const handleInputChange = (event) => {
        const { name, value } = event.target
        
        setUser({ ...useri, [name]: value })

        if(event.target.name === 'birthday'){
            console.log(event.target.value, 'birthdayy')

            setBirthday(event.target.value);
            console.log(event.target.value.split("/"), 'slited')

            
                // if(Number(event.target.value.split("/")[0]) > Number(31) || Number(event.target.value.split("/")[0]) < Number(1)){
                //     setError('Please type a day between 1 and 31')
                //     setIsValid(false)
                //     }else if( Number(event.target.value.split("/")[1]) > 12 || Number(event.target.value.split("/")[1]) < 1){
                //         setError('Please type a month between 1 and 12')
                //         setIsValid(false)
                //     }else if( event.target.value.split("/")[2].length !== 4){
                //         setError('Please type a valid year')
                //         setIsValid(false)
                //     }else{
                //     setIsValid(true)
                // }
        }
      }

         



   
        

      const handleUpload = async e => {
        const formData = new FormData();
        formData.append("picturelocation", image.raw);
      await axios.post(
          `/api/all/fansat/${User?._id}/uploadpicture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
            },
            body: formData
          }
        );
          setTrueImage(`https://api.starpokes.com/${image.raw.name}`)
       
     
      //   window.reload();
      };

    const handleChange = async e => {

      if (e.target.files.length) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        });
        dispatch(picture({
            image:{
                real:'',
                preview:URL.createObjectURL(e.target.files[0])
            }
          }))
          handleUpload()
      }
      
    };


    console.log(useri.birthday.split("/")[0], ' op')
    const submitForm = (event) => {
        event.preventDefault()

        if(useri.birthday.includes('\',',',','.')){
            console.log('ka apo ska nga keto ?')
        }else if(Number(useri.birthday.split("/")[0]) > Number(31) || Number(useri.birthday.split("/")[0]) < Number(1)){
            setError('Please type a day between 1 and 31')
            setIsValid(false)
            }else if( Number(useri.birthday.split("/")[1]) > 12 || Number(useri.birthday.split("/")[1]) < 1){
                setError('Please type a month between 1 and 12')
                setIsValid(false)
            }else if( useri.birthday.split("/")[2].length !== 4){
                setError('Please type a valid year')
                setIsValid(false)
            }else{
            setIsValid(true)

            let config = {
                name: event.target.name.value,
                birthday: event.target.birthday.value,
                email: event.target.email.value,
                currency: userCurrency,
                headers: {
                    Authorization:
                      "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
                  },
              };
    
            if (!useri.name || !useri.username) return
        
            // props.addUser(user)
            // setUser(useri)
    
    
            axios.put(`api/user/fansat/${useri?._id}`,config).then(res => {
    
                setIsUpdated(true);
                setUser(useri)
                dispatch(currency({
                    currency:userCurrency
                   }))
                   dispatch(user(useri))
            })
            setMsg('Profile updated successfully')
            setTimeout(function(){ 
                setMsg('')
                setError('')
             }, 6000);
        }

   
     
  

      }
        
    useEffect(() => {
        axios.get('api/currency').then(res => {
            setCurrency(res.data);  
        })
    }, []);


    return(
        <>
       

    <div className="container edit-profile">
        <div className="row">
            <div className="col-6">
                <h1>Edit Profile</h1>
             
            </div>
        </div>

            

        <div className="row">
            <div className="col-md-12">
                <div className={msg ? 'error--msg' : 'd-none'} style={{width:'300px'}}>
                    {msg}
                </div>
            </div>
        </div>
        <div className="row">
        <div className="col-12 d-block d-md-none">
                    <label htmlFor="upload-button">
                  
    
                        {picturelocation?.image.preview !== null ? (
                            <>
    
                   
                        <img src={picturelocation?.image.preview }  width="300" height="300" />
                       
                        </>
                        ) : (
                        <>
                     
                        <img src={picturelocation?.image.real} alt=""/>
                           
                        </>
                        )}
                        <div className="bottom">
                            <span><img src={Lapsi} alt="lapsi"/> {lang?.changeProfilePicture} </span>
                        </div>
                    </label>
                  
                    <input
                        type="file"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={handleChange}
                    />
                
                    </div>
        </div>
            <div className="row">
                <div className="col-md-6">
                    <form action="" onSubmit={submitForm}>
                        <div className="row">
                            <div className="col-md-12">
                            <input type="text" name="name" placeholder="Name Surname" defaultValue={reduxUser?.name} onChange={handleInputChange} />
                                <p>{lang?.yourName}</p>
                            </div>
                            <div className="col-md-12">
                            
                            <input type="text" name="birthday" pattern="^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$" id="birthday" placeholder="Day/Month/Year" defaultValue={reduxUser?.birthday} onChange={handleInputChange}  />
                            <p>{error}</p>
                            <p>{lang?.yourBirthday} <small>(dd/mm/yyyy)</small></p>
                            </div>
                            <div className="col-md-12">
                            <input type="email" name="email" placeholder="Example@starpokes.com" defaultValue={reduxUser?.email} onChange={handleInputChange}/>
                            <p>{lang?.yourEmail}</p>
                            </div>
                            <div className="col-md-12 selekti">
                                <h6>{lang?.currency}</h6>
                                { reduxUser !== null &&
                                   <Select defaultValue={reduxUser?.currency} 
                                    // showSearch
                                    optionFilterProp="children"
                                  
                                    onChange={getCurrency}
                                    onSearch={onSearch}
                                    >                       
                                    {
                                        currenci !== null && Object.keys(currenci).map(function(key, index) {
                                        
                                            return(
                                                <Option key={index} value={currenci[key]?.AlphabeticCode}> {currenci[key]?.Currency} </Option>
                                            )
                                            
                            
                                        })
                                    }
                                    </Select>
                                }
                         
                            </div>
                        </div>
                 
                        <button type="submit">Update Profile</button>
                    </form>
                
                    {/* <Currency /> */}

             
                </div>
                <div className="col-md-6 d-none d-md-block">
                    
              
                
                <label htmlFor="upload-button">
              

                    {picturelocation?.image.preview !== null ? (
                        <>

               
                    <img src={picturelocation?.image.preview }  width="300" height="300" />
                   
                    </>
                    ) : (
                    <>
                 
                    <img src={picturelocation?.image.real} alt=""/>
                       
                    </>
                    )}
                    <div className="bottom">
                        <span><img src={Lapsi} alt="lapsi"/> {lang?.changeProfilePicture} </span>
                    </div>
                </label>
              
                <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={handleChange}
                />
            
                </div>
            </div>
        </div>
        </>

    )
}

export default EditProfile