import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { currentlanguage, translations,user } from "../../store/actions";

import {useSelector} from 'react-redux'


const FormDownloadableOther = (props) => {
  const tokeni = window.localStorage.getItem("user", JSON.stringify());

  


  // const user = JSON.parse(tokeni);
  const [shoutouttypes, setShoutouttypes] = useState(null);
  const [shoutoutCat, setShoutoutCat] = useState(null);
  const {user} = props


  const [message, setMessage] = useState('')
  const [isSubmitDisabled, setSubmitDisabled] = useState('')
  const [relation,setRelations] = useState([])

  const [errorMsg, setErrorMsg] = useState('');


  const perkthimet = useSelector((state) => state.translations);

  const currentLanguage = useSelector((state) => state.currentlanguage)

  const useri = useSelector((state) => state.user)


  var m = new Date();
  var dateString =
      ("0" + m.getUTCDate()).slice(-2) + "/" +
      ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
      m.getUTCFullYear() + " " +
      ("0" + m.getUTCHours()).slice(-2) + ":" +
      ("0" + m.getUTCMinutes()).slice(-2);


  const handle__form__order = async (event) => {

    if(event.target.ordertext.value === '' || event.target.shoutid.value === '' || event.target.personName.value === '' || event.target.age.value === '' || event.target.gender.value === ''){
      event.preventDefault();
      setErrorMsg('Please fill up the fields')
    }else{

      event.preventDefault();
        event.persist();

    let getbankingurl = {
      artistId: props.artistid,
      fanId: user?.fan_id,
      isDownload:1
    }


    try{
      axios.post("/api/orders/web/getbanking", getbankingurl).then((response)=> {

        // window.location.replace(response.data.url);
        // event.preventDefault();
        // event.persist();

        
        let child = window.open(response.data.url, '_blank', 'width=640, height=740, location=yes');



        var timer = setInterval(checkChild, 500);
  
        function checkChild() {
            if (child.closed) {
            
  
              axios.get(`/api/orders/checkorderstatus/${response.data.sid}`)
              .then((res) => {
              
  
                if(res.data.message === 'Paid'){
                  setMessage('Your order has been successfully sent')
                 props.orderStatus({
                  paymentstatus:true,
                   message:'Your order has been successfully sent'
                 })
                  
                }else{
                  setMessage('You either canceled or credit card invalid')
                  props.orderStatus({
                    paymentstatus:false,
                    message:'You either canceled or credit card invalid'
                  })
                }
              })
              
              setSubmitDisabled('')  
                clearInterval(timer);
            }
        }

        props.closeModalFromChild()

        console.log(event.target.gender.value, 'gender')


        let new_request = {
          artistid: props.artistid,
          fanid: user?.fan_id,
          locationemail:user?.homeCountryCode,
          isdownload: 1,
          ordertext: event.target.ordertext.value,
          ShoutTypeID: event.target.shoutid.value,
          order_date: dateString,
          videoIsFor: event.target.personName.value,
          from: user?.name,
          relation: event.target.relation.value,
          gender: event.target.gender.value,
          age: event.target.age.value,
          amount: props.amount,
          currency: props.currency,
          isforsomeone:true,
          paymentURL:response.data.url,
          product:null,
          subproduct:null,
          purchaseDetails:null,
          platform:"Web",
          payment:{
            oid:response.data.oid,
            sid:response.data.sid,
            url:response.data.url,
         },
        };



        try {
          axios
           .post("api/orders/web/addnewrequser", new_request)
           .then((response) => {
             event.preventDefault();
           });
   
   
   
   
       } catch (err) {
         console.log(err);
       }

      })
    }catch{

    }
  }
  };

  
  const getRelations = async () => {
    try{
      const relations = await axios.get(`api/relations/rel/${currentLanguage}`)

      setRelations(relations.data)
    }catch(err){
      console.log(err)

    }
  }
  
  const getShoutout = async () => {
    try {
      const shoutouts = await axios.get(`api/shoutout/getparent/${currentLanguage}`,{
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
        }
      });
      setShoutouttypes(shoutouts.data);
    
    } catch (err) {
      console.log(err);
    }
  };
  const setshoutCat = async (e) => {
    try {
     
      // let shoutouts = await axios.get(
      //   `api/shoutout/shoutcategories/${event.target.value}`
      // ,{
      //   headers: {
      //     Authorization: "Bearer " + JSON.parse(localStorage.getItem("user"))?.token
      //   }
      // });


      let shoutouts = await axios.get(`api/shoutout/getchildbyparent/${e.target.value}/${currentLanguage}`)
      setShoutoutCat(shoutouts.data);
    } catch (err) {
      console.log(err);
    }
  };

  
  useEffect(() => {
    getRelations();
    getShoutout();
  }, []);
  return (

    <form action='' onSubmit={handle__form__order}>
      <div className=' forma'>
        <div className='row'>
          <div className='col-md-12'>
            <input className='fullwidth' type='text' name='personName' />
            <label htmlFor=''> {perkthimet?.lang?.whoIsThisVideoFor}</label>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 check-boxat'>
            <div className="row">
              <div className="col">
              <input className='' type='radio' id="m" name='gender' value='M' />
            <label htmlFor="m">M</label>
              </div>
              <div className="col">
              <input className='kekbox' type='radio' id="f" name='gender' value='F' />
            <label htmlFor="f">F</label>
              </div>
              <div className="col">
              <input className='kekbox' type='radio' id="o" name='gender' value='O' />
            <label htmlFor="o">O</label>
              </div>
              <div className="col-md-12">
              <label>{perkthimet?.lang?.theirGenderIs}</label>

              </div>
            </div>
            
         
         
          </div>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <input className='fullwidth age' onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
 type='number' name='age' />
            <label>{perkthimet?.lang?.theirAge}</label>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <select name='relation'>
              <option style={{ display: "none" }}>
                {perkthimet?.lang?.whatsYourRelationToThisPerson}
              </option>
              {relation?.map((el, key) => {
                return (
                  <option style={{ color: "black", textTransform:"capitalize" }} value={el.relation_id} key={key}>
                    {el.relationtype}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='col-12'>
            <select name='occassion' onChange={setshoutCat}>
              <option style={{ color: "black", display: "none" }}>
                {perkthimet?.lang?.chooseOccassion}
              </option>
              {shoutouttypes?.map((el, key) => {
                return (
                  <option style={{ color: "black" }} value={el?.category} key={key}>
                {el?.category}
                  </option>
                );
              })}
            </select>
          </div>
          {shoutoutCat?.length > 0 ? (
            <div className='col-12'>
              <select name='category' name='shoutid'>
                <option style={{ color: "black", display: "none" }}>
                  {perkthimet?.lang?.chooseTheOccassionCategory}
                </option>
                {shoutoutCat?.map((el, key) => {
                  return (
                    <option style={{ color: "black" }} value={el.shoutid} key={key}>
                      {el.shoutdesc}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
        </div>
        <div className='row '>
          <div className='col-12'>
            <textarea type='text' className='fullwidth' name='ordertext'  style={{border:"1px solid white"}}/>
            <label>{perkthimet?.lang?.instructionsOnWhatYouWantTheCelebToSay}</label>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-6 text-center'>
          <input type='submit' value={perkthimet?.lang?.conTinue} className='submitform'/>
          </div>
          <div className="col-md-12">
            <div className={errorMsg ? 'error--msg' : 'd-none'}>
                {errorMsg}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </form>
  );
};

export default FormDownloadableOther;
