import React, { useState, useEffect } from "react";
import {useDispatch} from "react-redux"
// import {login} from "../../old-store/features/userSlice"
import "./Login.scss";
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {user, picture} from "../../store/actions"

const Login = (props) => {
  //   const baseUrl = "https://api.starpokes.com/api/api/artist/login";
  //   const localUrl = "localhost:5000/api/artist/login";
  // if (window.localStorage.getItem("user", JSON.stringify())) {
  //   props.history.replace("/homepage");
  // }
  const [passError, setPassError] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [color, setColor] = useState("");
  const [shadow, setBoxShadow] = useState("");
  const [eye, setEye] = useState("");
  const [messageError, setMessageError] = useState("");
  const [error, setError] = useState(null);
  const [recaptchaLogin, setRecaptchaLogin] = useState(true);
  const [pass, setMsg] = useState("");
  const [formControl, setFormControl] = useState();
  const [userAuth, setAuth] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();


  const [errorMsg, setErrorMsg] = useState('');
  const [trueLogin, setLogin] = useState(null)

 

  const handleFbAuth = async (e) => {
    // e.preventDefault();

    axios.get("auth/facebook/callback").then((res) => {
    });
  };

  const handleChange = (event) => {
    setAuth({ ...userAuth, [event.target.name]: event.target.value });
  };

  useEffect(() => {

  }, []);

  const notify = (msg) => toast(msg);
  const handleLogin = async (event) => {

    event.preventDefault();
    
    let data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };
    let logindata;

    try {
      logindata = await axios.post("api/artist/login", data).then(
        res => {
          console.log(res.data,'res.data.login')
          setLogin(true)
        }
      ).catch(err => {
        // setErrorMessage('')
        console.log(err)
      })
   
    } catch (err) {
      notify("Please fill in the correct Username and Password");
   
    }
    try {
      window.localStorage.setItem(
        "user",
        JSON.stringify({
          login: true,
          token: logindata.data.token,
          useri: logindata.data,
        })
      );
      window.localStorage.setItem(
        "profilepicture",
        JSON.stringify({
          image: logindata.data.user.picturelocation,
        })
      );
    } catch (err) {
     
      setErrorMsg("Please fill in correct username and password fields");
    }

    // dispatch(user({
    //   login: true,
    //   token: logindata.data.token,
    //   user:logindata.data
    // }))
   

    try { 
        // props.history.replace(`/`);
        // window.location.href = "/profile"
        props.history.replace(`/profile/${logindata.data.username}`);
    } catch (err) {
      console.log(err);
    }
  };
  

  return (
    <div className='row no-gutters'>
      <div className='col-md-5 '>
        <div className='leftside__container'>
          <div className='welcome__message'>
            <p>Welcome!</p>
            <span>Please Sign-in to continue</span>
          </div>
          <div className='login__form'>
            <form onSubmit={handleLogin}>
              <div className='form-group'>
                <label>Username</label>
                <input
                  type='text'
                  name='email'
                  style={{ border: formControl, boxShadow: shadow }}
                  // className="form-control "
                  id='exampleInputEmail1'
                  onChange={handleChange}
                  aria-describedby='emailHelp'
                  placeholder='Username'
                  required
                />
                <span className='error__msg'>{errMsg}</span>
              </div>
              <div className='form-group password__class'>
                <label>Password</label>
                <input
                  type={eye ? "text" : "password"}
                  name='password'
                  className='form-control input__pass'
                  onChange={handleChange}
                  id='exampleInputPassword1'
                  placeholder='Password'
                  maxLength='65'
                  required
                />
              </div>
              <div className='forget__pass'>
                {/* <NavLink
                  className="forgot__password"
                  to="forgot-password-email"
                >
                  Forgot Password
                </NavLink> */}
              </div>
              <span className='error__msg'>
                {pass} {messageError}
              </span>
              <span className='error__msg'>{passError}</span>
              <div className='login__button'>
                <input type='submit' value='Login'/>
              </div>
            </form>
            <ToastContainer />
          </div>
          <div>
            <div className='social__login'>
              <button className='social__button' onClick={() => handleFbAuth()}>
                <i className='fa fa-facebook mr-3'></i>Continue from Facebook
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='col-md-7 rightside__picture'>
        <div className='text__content'>
          <p>Make someone's day</p>
          <div className='personalized'>
            <span>Personalized video shoutouts from their favorite people</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
