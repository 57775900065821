import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "../../axios";
import Artist from "../Artist/Artist";
import "./singleCategory.scss";
import Vip from "../VipCard/Vip/Vip"
import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from "@emotion/core";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Homeadds from "../Adds/HomeAdds"

import {useSelector} from "react-redux"

import {user,currentlocation} from '../../store/actions';

const SingleCategory = (props) => {
  //
  let kategoria = props?.location?.state?.cat?.category_name
  let catId = props?.location?.state?.cat?.categoryid

  let subkategorite = props?.location?.state?.cat?.subcats

  //states
  const [getArtists, setArtists] = useState([]);


  const [getSubCats, setSubCats] = useState([])
  const [loading, setLoading] = useState(true)

  const [checked, setChecked] = React.useState();

  const [artistsLoading, setArtistLoading] = useState(false)
  
  //selectors
  const useri = useSelector (state => state.user)
  const currentlocation = useSelector((state) => state.currentlocation)

  const location = useLocation();


  const getSingleSubCategory = () => {
    axios
    .post(`api/categories/artistsbysubcat/${currentlocation}`,{categories:[Number(checked)], parentcat: catId })
    .then((res) => {
      setArtists(res.data)

    })
  }


  const Artists = (catarr) => {
    if(useri === null){
      axios
        .post(`api/categories/artistsbysubcat/all`,{categories:catarr, parentcat: catId })
        .then((res) => {
          setArtists(res.data)
          setLoading(false)
        })
        .catch((error) => console.log(error));
      
    }else{
      axios
      .post(`api/categories/artistsbysubcat/${currentlocation}`,{categories:catarr, parentcat: catId })
      .then((res) => {
        setArtists(res.data)
        setLoading(false)
      })
      .catch((error) => console.log(error));
    }
  };

  
  const handleChange = (e) => {
    // setSelectedCategory(e.target.value)
    // setChecked(e.target.value)
    setArtistLoading(true)

    if(useri === null){
      axios
        .post(`api/categories/artistsbysubcat/${currentlocation}`,{categories:[Number(e.target.value)], parentcat: catId })
        .then((res) => {
          setArtists(res.data)
          setLoading(false)
          setArtistLoading(false)
        })
        .catch((error) => console.log(error));
      
    }else{
      axios
      .post(`api/categories/artistsbysubcat/${currentlocation}`,{categories:[Number(e.target.value)], parentcat: catId })
      .then((res) => {
        setArtists(res.data)
        setLoading(false)
        setArtistLoading(false)
      })
      .catch((error) => console.log(error));
    }

    // getSingleSubCategory()
  }


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 55
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 30
    }
  };
  const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

`;
let [color, setColor] = useState("#cb2a6f");


  const [openFilters, setOpenFilters] = useState(false)


  // Artists(getSubCats);


  function toggleFilters(){
    if(openFilters === false){
      setOpenFilters(true)
    }else{
      setOpenFilters(false)
    }
  }

  function triggerFromCatName (item){
      setChecked(item?.category_id)

      setArtistLoading(true)
    
      if(useri === null){
        axios
          .post(`api/categories/artistsbysubcat/all`,{categories:[Number(item?.category_id)], parentcat: catId })
          .then((res) => {
            setArtists(res.data)
            setArtistLoading(false)
          })
          .catch((error) => console.log(error));
        
      }else{
        axios
        .post(`api/categories/artistsbysubcat/${currentlocation}`,{categories:[Number(item?.category_id)], parentcat: catId })
        .then((res) => {
          setArtists(res.data)
          setArtistLoading(false)
        })
        .catch((error) => console.log(error));
      }
      window.scrollTo({
        top: 50,
        behaviour: "smooth",
      });
  }

  window.scrollTo({
    top: 50,
    behaviour: "smooth",
  });

  useEffect(() => {
    setChecked(false)
 
   setSubCats([]);
    Artists([]);
    window.scrollTo({
      top: 50,
      behaviour: "smooth",
    });

  }, [location]);

  return (
  
       loading === true ? 
      <div className="scale--container">
         <ScaleLoader color={color} css={override} loading={loading} height='90' width='10' size={150} />
      </div>
    :
    <>
    <div className='vip--container custom__container cats--container'>
      <div className='row'>
        <div className='col-md-12'>
          <h1>{kategoria}</h1>
          <p onClick={toggleFilters} style={{cursor:"pointer"}}>Select Subcategory...</p>
        </div>
      </div>
      <div className={`filters ${openFilters === true ? 'open--filters' : ''}`}>
        <div className="row">
          {
                 subkategorite?.map((item,index) => {
                  return(
                    <div className="col-md-2" key={item?.categoryid}>
                
                   <input type="radio" id={item?.categoryid} defaultValue={item?.categoryid} name="demo01" onChange={handleChange} onClick={(e) => setChecked(e.target.value)} /><label htmlFor={item?.categoryid}>{item?.description}</label>

          
                      
                
                    </div>
                  )
                })
          }
        </div>
      </div> 
    </div>


    <div className={`vip--container ${getArtists.length > 1 ? '' : 'custom__container' }`}>
      <div className="row">
          <div className="col-md-10">

        
{ 
  artistsLoading === true ?  
  <ScaleLoader color={color} css={override} loading={loading} height='90' width='10' size={150} /> : 
  getArtists.length > 1 ? 
  getArtists.map(function(items, index) {
    
    return(
 <div className="row singleCats" key={index}>
            {/* <div className="col">
              <div className="pos-relative" onClick={() => triggerFromCatName(items)}>
                <label className="text-direct" htmlFor={items?.category_id}>{items?.category_name}</label>

              </div>
             </div> */}

            <div className="col-1">
            <div className="pos-relative" onClick={() => triggerFromCatName(items)}>
              
              </div>
              <p className="bottoms-up" onClick={() => triggerFromCatName(items)}>
                <label className="text-direct" htmlFor={items?.category_id}>{items?.category_name}</label>

              </p>
            </div>
            
        
          <div className="col-md-10 custom--col">
          <Carousel
            //  partialVisible={true}
        
             swipeable={isMobile ? true : false}
             draggable={false}
             // showDots={true}
             responsive={responsive}
             ssr={true} // means to render carousel on server-side.
             infinite={false}
             // autoPlay={this.props.deviceType !== "mobile" ? true : false}
             autoPlay={false}
             autoPlaySpeed={5000}
             keyBoardControl={true}
             customTransition="all .5"
             transitionDuration={300}
             containerClass="carousel-container"
             removeArrowOnDeviceType={["tablet", "mobile"]}
                  >
          {
                            // {items?.artists?.map((item) => {
          items?.artists.map(function(item, index) {
                return(
            
                <Vip key={index} item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={items?.category_name}/>
              
                )
              })
          }
            </Carousel>
          </div>
      </div>
 
    );
      
  }) : getArtists.map(function(items, index){
    return(
      <div className="row" style={{marginTop:"30px"}} key={index}>
        <div className="col-md-12">
          {/* <h1>{}</h1> */}
        </div>
          {
                items?.artists.map(function(item, index) {

                 
                  if(items?.artists.length >= 1){
                    return(
                      <div className="col-md-2" key={index}>
                        <Vip item={item} id={item._id}  link={item.username} username={item.username} to={item.username} name={item.name} picturelocation={item.picturelocation} CategoryID={items?.category_name}/>
                      </div>
                  
                    )
                  }else{
                    return(
                      <div className="col-md-12 text-center">
                        <h1>No Artists in this subcategory</h1>
                    </div>
                    )
                  }
                })
          }
      </div>
  
    )
  })

}
  </div>
  <div className="col-md-2">
    <Homeadds/>
  </div>
     </div>
</div>

    </>
  );
  
};

export default SingleCategory;
